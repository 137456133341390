import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Chip, Typography } from '@mui/material';
import {
  EditionIcon,
  DraggablelIcon,
  TrashIcon,
} from '@boletia/blt-components/src/Icons';

export const SurveyChip = ({ label }) => (
  <Box
    sx={{
      background: '#E8E9F9',
      color: '#3D49CC',
      padding: '2px 8px',
      borderRadius: '50px',
    }}
  >
    <Typography variant="body2">{label}</Typography>
  </Box>
);

const FormDraggableItem = ({
  children,
  onDelete,
  onEdit,
  id,
  ticketTypes,
  ticketTypesList,
  draggableSection,
  canEdit,
  sublabel,
}) => {
  return (
    <Box
      sx={{
        width: '100%',
        background: '#F4F6F8',
        borderRadius: '10px',
        padding: { xs: '10px 16px', md: '20px 28px 20px 32px' },
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        marginBottom: '20px',
      }}
    >
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          gap: '20px',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box sx={{ width: '50%' }}>{children}</Box>
        {ticketTypes && (
          <Box
            sx={{
              display: { xs: 'none', md: 'flex' },
              gap: { xs: '10px', md: '12px' },
              flexGrow: '1',
              flexWrap: 'wrap',
            }}
          >
            {ticketTypes
              .filter((ticket) =>
                ticketTypesList.find((ticketType) => ticket === ticketType.id),
              )
              .map((ticket) => {
                const ticketName = ticketTypesList.find(
                  (ticketType) => ticket === ticketType.id,
                );
                return <SurveyChip label={`#${ticketName?.name}`} />;
              })}
          </Box>
        )}
        {canEdit && (
          <Box
            sx={{
              display: 'flex',
              gap: { xs: '16px', md: '24px' },
              fontSize: { xs: '16px', md: '20px' },
              color: '#757575',
            }}
          >
            <EditionIcon
              fontSize="inherit"
              sx={{
                '&:hover': {
                  cursor: 'pointer',
                },
              }}
              onClick={() => {
                onEdit();
              }}
            />
            <TrashIcon
              fontSize="inherit"
              sx={{
                '&:hover': {
                  cursor: 'pointer',
                },
              }}
              onClick={() => onDelete(id)}
            />
            <Box {...draggableSection}>
              <DraggablelIcon fontSize="inherit" htmlColor="#AFAFAF" />
            </Box>
          </Box>
        )}
      </Box>
      {ticketTypes && (
        <Box
          sx={{
            display: { xs: 'flex', md: 'none' },
            gap: { xs: '10px', md: '12px' },
            flexGrow: '1',
            flexWrap: 'wrap',
          }}
        >
          {ticketTypes.map((ticket) => {
            const ticketName = ticketTypesList.find(
              (ticketType) => ticket === ticketType.id,
            );
            return <SurveyChip label={`#${ticketName?.name}`} />;
          })}
        </Box>
      )}
    </Box>
  );
};

FormDraggableItem.propTypes = {
  children: PropTypes.element,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  id: PropTypes.string,
  draggableSection: PropTypes.object,
  ticketTypes: PropTypes.array,
  ticketTypesList: PropTypes.array,
};

FormDraggableItem.defaultProps = {
  children: <></>,
  onDelete: () => {},
  onEdit: () => {},
  id: '',
  draggableSection: {},
  ticketTypes: [],
  ticketTypesList: [],
};

export default FormDraggableItem;
