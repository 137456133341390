import PropTypes from 'prop-types';
import { t } from 'i18next';
import { useLocation, useParams } from 'react-router';
import { styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, Link, Typography } from '@mui/material';
import {
  useOffSetTop,
  useResponsive,
  cssStyles,
  Logo,
  Iconify,
  IconButtonAnimate,
} from '@boletia/blt-ui';
import { HEADER, NAVBAR } from '@boletia/blt-ui/src/config';
import AccountPopover from '../../components/AccountPopover';
import EventsControlHeader from '../../../components/EventsControlHeader';
import { useGetBillboardQuery } from '../../../store/api/billboard.api';

const RootStyle = styled(AppBar, {
  shouldForwardProp: (prop) =>
    prop !== 'isCollapse' && prop !== 'isOffset' && prop !== 'verticalLayout',
})(({ isCollapse, isOffset, verticalLayout, theme }) => ({
  ...cssStyles(theme).bgBlur(),
  boxShadow: 'none',
  height: HEADER.MOBILE_HEIGHT,
  zIndex: theme.zIndex.appBar + 1,
  transition: theme.transitions.create(['width', 'height'], {
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up('lg')]: {
    height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH + 1}px)`,
    ...(isCollapse && {
      width: `calc(100% - ${NAVBAR.DASHBOARD_COLLAPSE_WIDTH}px)`,
    }),
    ...(isOffset && {
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
    }),
    ...(verticalLayout && {
      width: '100%',
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
      backgroundColor: theme.palette.background.default,
    }),
  },
}));

DashboardHeader.propTypes = {
  onOpenSidebar: PropTypes.func,
  isCollapse: PropTypes.bool,
  verticalLayout: PropTypes.bool,
};

export default function DashboardHeader({
  onOpenSidebar,
  isCollapse = false,
  verticalLayout = false,
}) {
  const { pathname } = useLocation();
  const { billboard, billboard_type: billboardType } = useParams();

  const { data: { subdomain = null } = {} } =
    useGetBillboardQuery(billboard, {
      skip: !billboard,
    }) || {};

  const isOffset =
    useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT) && !verticalLayout;

  const isDesktop = useResponsive('up', 'lg');

  return (
    <RootStyle
      isCollapse={isCollapse}
      isOffset={isOffset}
      verticalLayout={verticalLayout}
    >
      <Toolbar
        sx={{
          minHeight: '100% !important',
          px: { lg: 5 },
        }}
      >
        {isDesktop && verticalLayout && <Logo sx={{ mr: 2.5 }} />}
        {!isDesktop && (
          <IconButtonAnimate
            onClick={onOpenSidebar}
            sx={{ mr: 1, color: 'text.primary' }}
          >
            <Iconify icon="eva:menu-2-fill" />
          </IconButtonAnimate>
        )}
        {pathname === '/dashboard/check-in/events-control' && (
          <EventsControlHeader />
        )}
        {billboardType && (
          <Typography
            variant="h5"
            sx={{
              overflow: 'hidden',
              maxHeight: '30px',
              color: 'text.primary',
              textOverflow: 'ellipsis',
              display: 'inline-block',
              whiteSpace: 'nowrap',
            }}
            component="div"
          >
            {`${t('billboards.billboard')} ${billboardType}`}
          </Typography>
        )}
        <Box sx={{ flexGrow: 1 }} />
        <Stack
          direction="row"
          alignItems="center"
          spacing={{ xs: 0.5, sm: 1.5 }}
        >
          {billboard && subdomain && (
            <Link
              href={`https://boletia.com/eventos/${subdomain}/`}
              target="_BLANK"
              rel="noopener noreferrer"
              sx={{
                mr: 2,
                underline: 'none',
                color: (theme) => theme.palette.grey[700],
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Iconify icon="octicon:link-16" sx={{ fontSize: '20px' }} />
            </Link>
          )}
          <AccountPopover />
        </Stack>
      </Toolbar>
    </RootStyle>
  );
}
