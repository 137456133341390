import { createApi } from '@reduxjs/toolkit/query/react';
import { authBaseQuery } from '../utils/utils';

export const paymentMethodsApi = createApi({
  reducerPath: 'paymentMethodsApi',
  baseQuery: authBaseQuery({
    baseUrl: process.env.REACT_APP_API_EVENT,
  }),
  tagTypes: ['paymentMethods'],
  endpoints: (build) => ({
    getPaymentMethodsByEvent: build.query({
      query: (eventId) => `/payment-methods/events/${eventId}`,
      providesTags: ['paymentMethods'],
    }),
    getActiveCurrencyList: build.query({
      query: () => `/payment-methods/currencies`,
      providesTags: ['paymentMethods'],
    }),
    createPaymentMethod: build.mutation({
      query(data) {
        const { eventId, type } = data;
        return {
          url: `/payment-methods`,
          method: 'POST',
          headers: {
            event_id: eventId,
          },
          body: { type },
        };
      },
      invalidatesTags: ['paymentMethods'],
    }),
    deletePaymentMethod: build.mutation({
      query(data) {
        const { eventId, type } = data;
        return {
          url: `payment-methods/events/${eventId}`,
          method: 'DELETE',
          body: { type },
        };
      },
      invalidatesTags: ['paymentMethods'],
    }),
  }),
});

const filterDuplicates = (data) => {
  const uniqueData = Array.from(new Set(data?.map((item) => item.type))).map(
    (type) => data.find((item) => item.type === type),
  );
  return uniqueData;
};

export const useGetFilteredPaymentMethodsByEventQuery = (eventId) => {
  const originalHookResult =
    paymentMethodsApi.useGetPaymentMethodsByEventQuery(eventId);
  const modifiedData = filterDuplicates(originalHookResult.data);

  return {
    ...originalHookResult,
    data: modifiedData,
  };
};

export const {
  useGetPaymentMethodsByEventQuery,
  useGetActiveCurrencyListQuery,
  useCreatePaymentMethodMutation,
  useDeletePaymentMethodMutation,
} = paymentMethodsApi;
