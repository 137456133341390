import { useMemo } from 'react';
import useAuth from './useAuth';
import { roles } from '../routes/require-rol';

const useEdit = (role, exclude = '') => {
  const { auth } = useAuth();
  const canEdit = () => {
    return roles[auth.roles] >= roles[role] && auth.roles !== exclude;
  };
  return useMemo(() => ({ canEdit }), [canEdit]);
};

export default useEdit;
