import React, { useState } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  FormItem,
  FormSelect,
  FormsWrapper,
  FormText,
} from '@boletia/blt-components/src/Forms';
import { Button, Checkbox, FormControlLabel, Grid } from '@mui/material';
import FormModal from './FormModal';
import MultipleSelect from '../MultipleSelect';

const Multiple = ({ name, rules, data, reset, label }) => {
  const { control } = useFormContext();
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <MultipleSelect
          onChange={onChange}
          value={value}
          data={data}
          reset={reset}
          label={label}
          error={error}
        />
      )}
    />
  );
};

export const searchTicketId = (tickets, list) => {
  const filtered = tickets.map((ticket) => {
    const result = list.filter(
      (ticketType) => ticketType.name === ticket && ticketType.id,
    );
    return [...result];
  });
  return filtered.map((filter) => {
    const unwraped = filter[0];
    return unwraped.id;
  });
};

function TicketForm({ open, handleModal, value, ticketTypeList, onSubmit }) {
  const [required, setRequired] = useState(value?.required_question);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const schema = yup.object({
    ticket_types: yup
      .array()
      .when(
        '$isSubmitted',
        () =>
          isSubmitted &&
          yup.array().min(1, 'Selecciona al menos un tipo de boleto'),
      )
      .nullable(),
    question_title: yup
      .string()
      .max(200, 'El titulo no debe superar los 200 caracteres')
      .required('Ingresa una pregunta'),
    question_type: yup.string().required('Selecciona un tipo de pregunta'),
    help_text: yup
      .string()
      .max(200, 'El texto de ayuda no debe superar los 200 caracteres'),
  });

  const handleCheck = (event) => {
    setRequired(event.target.checked);
  };

  const formatData = (data) => {
    const isSelecQuestion = data.question_type === 'select';
    const dataFormatted = {
      help_text: data.help_text,
      question_title: data.question_title,
      question_type: data.question_type,
      ticket_types: searchTicketId(data.ticket_types, ticketTypeList),
      position: null,
      required_question: required,
    };
    if (Object.prototype.hasOwnProperty.call(data, 'question_id')) {
      dataFormatted.question_id = data.question_id;
    }
    if (isSelecQuestion) {
      dataFormatted.options = [];
    }
    setRequired(false);
    return dataFormatted;
  };

  return (
    <FormModal
      title="Pregunta de registro por boleto"
      open={open}
      handleModal={handleModal}
    >
      <FormsWrapper
        value={value}
        onSubmit={(d, { reset }) => {
          onSubmit(formatData(d));
          setIsSubmitted(false);
          handleModal(false);
          reset();
        }}
        resolver={yupResolver(schema)}
      >
        <Grid
          container
          spacing={4}
          sx={{ padding: { xs: '10px', md: '32px' } }}
        >
          <Grid item md={6} xs={12}>
            <FormItem
              name="ticket_types"
              label="Tipos de boleto"
              labelRequired
              Component={MultipleSelect}
              componentProps={{
                data: ticketTypeList,
                label: 'Tipos de boleto',
              }}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <FormText
              name="question_title"
              label="Título de la pregunta"
              componentProps={{
                fullWidth: true,
              }}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <FormText
              name="help_text"
              label="Texto de ayuda"
              labelDetail="(opcional)"
              componentProps={{
                fullWidth: true,
              }}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <FormSelect name="question_type" label="Tipo de pregunta">
              <option hidden selected>
                Selecciona una opción
              </option>
              <option value="text">Texto</option>
              <option value="text_area">Párrafo</option>
              <option value="select">Seleccionar de una lista</option>
            </FormSelect>
          </Grid>

          <Grid item md={12} xs={12}>
            <FormControlLabel
              control={<Checkbox checked={required} onChange={handleCheck} />}
              label="Marcar como obligatoria"
            />
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            gap: '10px',
            padding: '24px',
            borderTop: '1px solid #EDEFF2',
          }}
        >
          <Button
            variant="outlined"
            onClick={() => {
              setIsSubmitted(false);
              setRequired(false);
              handleModal(false);
            }}
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            type="submit"
            onClick={() => setIsSubmitted(true)}
          >
            Agregar pregunta
          </Button>
        </Grid>
      </FormsWrapper>
    </FormModal>
  );
}

export default TicketForm;
