import PropTypes from 'prop-types';
import { Avatar, Iconify } from '@boletia/blt-ui';
import { Box, Button, Dialog, Stack, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';
import { useGetEventQuery } from '../../../store/api/event.api';

export default function RequireParentDialog({ parentEventId }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { data } = useGetEventQuery(parentEventId);

  return (
    <Dialog open>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          padding: '40px',
          pb: '24px',
        }}
      >
        <Avatar
          color="primary"
          sx={{
            width: '64px',
            height: '64px',
            marginBottom: '24px',
          }}
        >
          <Iconify
            icon="ooui:article-redirect-rtl"
            sx={{
              color: '#ffffff',
            }}
            width={40}
            height={40}
          />
        </Avatar>
        <Typography variant="h6" fontWeight={500} mb={5}>
          <Trans
            i18nKey="checkin.redirectModal.description"
            values={{ parentName: data?.event?.name, parentEventId }}
            components={{
              strong: (
                <Typography
                  variant="h6"
                  component="span"
                  sx={{ textTransform: 'uppercase' }}
                />
              ),
            }}
          />
        </Typography>
        <Stack direction="row" gap={2}>
          <Button
            variant="outlined"
            onClick={() => navigate('/dashboard/check-in/events-control')}
          >
            {t('checkin.redirectModal.cancel')}
          </Button>
          <Button
            variant="contained"
            onClick={() =>
              navigate(pathname.replace(/\/\d+\//, `/${parentEventId}/`))
            }
          >
            {t('checkin.redirectModal.continue')}
          </Button>
        </Stack>
      </Box>
    </Dialog>
  );
}

RequireParentDialog.propTypes = {
  parentEventId: PropTypes.string.isRequired,
};
