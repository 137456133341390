import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  IconButton,
  InputBase,
  MenuItem,
  Select,
  Typography,
  Button,
} from '@mui/material';
import { PlusIcon } from '@boletia/blt-components/src/Icons';
import TrashIcon from '@boletia/blt-components/src/Icons/TrashIcon';

export const Focusable = ({
  optionText,
  reference,
  error,
  setOption,
  setError,
  option,
  addOption,
}) => {
  useEffect(() => {
    reference?.current?.focus();
  }, []);
  return (
    <InputBase
      autoFocus
      inputRef={reference}
      ref={optionText}
      sx={{
        'width': '100%',
        'border': !error ? '1px solid #F4F6F8' : '1px solid #B72136',
        'padding': '12px',
        'borderRadius': '8px',
        '&:hover': {
          backgroundColor: 'white',
        },
      }}
      value={option}
      onChange={(e) => {
        setOption(e.target.value);
        setError(false);
      }}
      onKeyDown={(e) => e.stopPropagation()}
      onKeyPress={(e) => {
        e.stopPropagation();
        if (e.code === 'Enter') addOption(e);
      }}
    />
  );
};

export const Item = ({ label, onDelete }) => (
  <MenuItem
    sx={{
      display: 'flex',
      justifyContent: 'space-between',
      background: '#F4F6F8',
      padding: '12px',
      margin: '4px auto',
      borderRadius: '8px',
      width: '95%',
    }}
    value={label}
  >
    <Typography sx={{ fontWeight: 500 }}>{label}</Typography>
    <IconButton
      onClick={(e) => {
        e.stopPropagation();
        onDelete();
      }}
    >
      <TrashIcon />
    </IconButton>
  </MenuItem>
);

function CustomInput({
  type,
  questionName,
  setQuestionName,
  helpText,
  setHelpText,
  options,
  setOptions,
}) {
  const optionText = useRef(null);
  const input = useRef();
  const [option, setOption] = useState('');
  const [error, setError] = useState(false);
  const [alert, setAlert] = useState(false);
  const [alertQ, setAlertQ] = useState(false);

  const addOption = () => {
    if (option !== '') {
      setOptions([...options, { option_value: option }]);
      setOption('');
      optionText.current.children[0].value = '';
    } else {
      setError(true);
    }
  };

  const deleteOption = (id) => {
    const copyOptions = [...options];
    copyOptions.splice(id, 1);
    setOptions(copyOptions);
  };

  return (
    <>
      {type !== 'select' ? (
        <Box
          sx={{
            width: '100%',
            background: '#F4F6F8',
            borderRadius: '8px',
            border: '1px solid #cfd8e2',
            position: 'relative',
          }}
        >
          <InputBase
            disabled
            fullWidth
            placeholder={questionName}
            sx={{ padding: '12px 14px 11px 14px' }}
            multiline={type === 'text_area'}
            rows={type === 'text_area' ? 3 : 1}
          />
          <InputBase
            sx={{
              width: 'calc(100% - 24px)',
              padding: '0 0 0 5px',
              flex: 1,
              position: 'absolute',
              top: -18,
              left: 12,
              background: '#FFF',
              border: !alertQ ? '1px solid #DCE0E4' : '1px solid #F00',
              borderRadius: '3px',
              fontSize: '12px',
            }}
            placeholder="titulo de la pregunta"
            value={questionName}
            onChange={(e) => {
              if (e.target?.value?.length > 200) {
                setAlertQ(true);
              } else {
                setAlertQ(false);
                setQuestionName(e.target.value);
              }
            }}
          />
          <InputBase
            sx={{
              width: 'calc(100% - 24px)',
              padding: '0 0 0 5px',
              flex: 1,
              position: 'absolute',
              bottom: -18,
              left: 12,
              background: '#FFF',
              border: !alert ? '1px solid #DCE0E4' : '1px solid #F00',
              borderRadius: '3px',
              fontSize: '12px',
            }}
            placeholder="Texto de ayuda"
            value={helpText}
            onChange={(e) => {
              if (e.target?.value?.length > 200) {
                setAlert(true);
              } else {
                setAlert(false);
                setHelpText(e.target.value);
              }
            }}
          />
        </Box>
      ) : (
        <Box
          sx={{
            position: 'relative',
            width: '100%',
          }}
        >
          <Select sx={{ width: '100%' }}>
            <MenuItem sx={{ background: '#fff' }}>
              <Focusable
                optionText={optionText}
                reference={input}
                setOption={setOption}
                setError={setError}
                option={option}
                addOption={addOption}
              />
            </MenuItem>
            {options.map((opt, i) => (
              <Item
                label={opt?.option_value}
                onDelete={() => deleteOption(i)}
              />
            ))}
            <MenuItem
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
                padding: '12px',
              }}
              onClick={(e) => {
                addOption(e);
                input.current?.focus();
              }}
            >
              <PlusIcon sx={{ width: 10, height: 10 }} color="secondary" />
              <Typography color="secondary">Agregar opción</Typography>
            </MenuItem>
          </Select>
          <InputBase
            sx={{
              width: 'calc(100% - 24px)',
              padding: '0 0 0 5px',
              flex: 1,
              position: 'absolute',
              top: -18,
              left: 12,
              background: '#FFF',
              border: !alertQ ? '1px solid #DCE0E4' : '1px solid #F00',
              borderRadius: '3px',
              fontSize: '12px',
            }}
            placeholder="Pregunta"
            value={questionName}
            onChange={(e) => {
              if (e.target?.value?.length > 200) {
                setAlertQ(true);
              } else {
                setAlertQ(false);
                setQuestionName(e.target.value);
              }
            }}
          />
          <InputBase
            sx={{
              width: 'calc(100% - 24px)',
              padding: '0 0 0 5px',
              flex: 1,
              position: 'absolute',
              bottom: -18,
              left: 12,
              background: '#FFF',
              border: !alert ? '1px solid #DCE0E4' : '1px solid #F00',
              borderRadius: '3px',
              fontSize: '12px',
            }}
            placeholder="Texto de ayuda"
            value={helpText}
            onChange={(e) => {
              if (e.target?.value?.length > 200) {
                setAlert(true);
              } else {
                setAlert(false);
                setHelpText(e.target.value);
              }
            }}
          />
        </Box>
      )}
    </>
  );
}

export default CustomInput;
