import PropTypes from 'prop-types';
import { createApi } from '@reduxjs/toolkit/query/react';
import { authBaseQuery } from '../utils/utils';

export const boostApi = createApi({
  reducerPath: 'boostApi',
  baseQuery: authBaseQuery({
    baseUrl: process.env.REACT_APP_API_AUTH,
  }),
  tagTypes: ['budget'],
  endpoints: (build) => ({
    getBudget: build.query({
      query: (userID) => `/boost/v1/budget/${userID}`,
      providesTags: ['budget'],
    }),
    // postBudget: build.mutation({
    //   query: (userID) => ({
    //     url: `/boost/v1/budget/${userID}`,
    //     method: 'POST',
    //   }),
    //   invalidatesTags: ['budget'],
    // }),
    postSuggestedBudget: build.mutation({
      query: ({ ownerID, ownerType }) => {
        validatePostSuggestedBudgetArgs({ ownerID, ownerType });
        return {
          url: '/boost/v1/campaign/suggested',
          method: 'POST',
          body: {
            owner_id: ownerID,
            owner_type: ownerType,
          },
        };
      },
      invalidatesTags: ['budget'],
    }),
    postPayment: build.mutation({
      query: ({
        userID,
        UserID = '',
        amount = 0,
        currency = '',
        customerID = '',
        sourceID = '',
      }) => ({
        url: `/boost/v1/pay/${userID}`,
        method: 'POST',
        body: {
          UserID,
          amount,
          currency,
          customer_id: customerID,
          source_id: sourceID,
        },
      }),
    }),
    getTransactions: build.query({
      query: (userID) => `/boost/v1/transaction/${userID}`,
      providesTags: ['transactions'],
    }),
    postImgUrl: build.mutation({
      query: ({ name = '' }) => ({
        url: `/boost/v1/campaign/img/url`,
        method: 'POST',
        body: {
          name,
        },
      }),
    }),
    getCampaigns: build.query({
      query: (userID) => `/boost/v1/${userID}`,
      providesTags: ['campaigns'],
    }),
    postCampaign: build.mutation({
      query: ({
        userID,
        budgetExternal,
        endDate,
        name,
        ownerID,
        ownerName,
        ownerType,
        startDate,
      }) => {
        validatePostCampaignArgs({
          userID,
          budgetExternal,
          endDate,
          name,
          ownerID,
          ownerName,
          ownerType,
          startDate,
        });
        return {
          url: `/boost/v1/${userID}`,
          method: 'POST',
          body: {
            budget_external: budgetExternal,
            end_date: endDate,
            name,
            owner_id: ownerID,
            owner_name: ownerName,
            owner_type: ownerType,
            start_date: startDate,
          },
        };
      },
      invalidatesTags: ['campaigns'],
    }),
    getCampaign: build.query({
      query: ({ userID, campaignID }) =>
        `/boost/v1/${userID}/campaign/${campaignID}`,
    }),
    putCampaign: build.mutation({
      query: ({
        userID,
        campaignID,
        body: {
          budgetExternal = 0,
          budgetInternal = 0,
          endDate = '',
          name = '',
          ownerID = 0,
          ownerName = '',
          ownerType = '',
          startDate = '',
          status = '',
        },
      }) => ({
        url: `/boost/v1/${userID}/campaign/${campaignID}`,
        method: 'PUT',
        body: {
          budget_external: budgetExternal,
          budget_internal: budgetInternal,
          campaign_id: campaignID,
          end_date: endDate,
          name,
          notify: '',
          number_ads: 0,
          owner_id: 0,
          owner_name: 'string',
          owner_type: 'string',
          showed_notify: true,
          start_date: '2024-06-18T06:20:00.062Z',
          status: 'string',
        },
      }),
      invalidatesTags: ['campaigns'],
    }),
    deleteCampaign: build.mutation({
      query: ({ userID, campaignID }) => ({
        url: `/boost/v1/${userID}/campaign/${campaignID}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['campaigns'],
    }),
    putCampaignStatus: build.mutation({
      query: ({ userID, campaignID, status, message, superadmin }) => ({
        url: `/boost/v1/${userID}/campaign/${campaignID}/status`,
        method: 'PUT',
        body: {
          status,
          message,
        },
        headers: {
          superadmin,
        },
      }),
      invalidatesTags: ['campaigns'],
    }),
    postAd: build.mutation({
      query: ({
        userID,
        campaignID,
        description,
        actionCall,
        squareName,
        horizontalName,
        name,
      }) => {
        validatePostAdArgs({
          userID,
          campaignID,
          description,
          actionCall,
          squareName,
          horizontalName,
          name,
        });
        return {
          url: `/boost/v1/${userID}/campaign/${campaignID}`,
          method: 'POST',
          body: {
            description,
            cta: actionCall,
            key_image1: squareName,
            key_image2: horizontalName,
            name,
          },
        };
      },
      invalidatesTags: ['campaigns'],
    }),
    putAd: build.mutation({
      query: ({
        userID,
        campaignID,
        adID,
        description,
        actionCall,
        squareName,
        horizontalName,
        name,
      }) => ({
        url: `/boost/v1/${userID}/campaign/${campaignID}/ad/${adID}`,
        method: 'PUT',
        body: {
          description,
          cta: actionCall,
          key_image1: squareName,
          key_image2: horizontalName,
          name,
        },
      }),
      invalidatesTags: ['campaigns'],
    }),
    deleteAd: build.mutation({
      query: ({ userID, campaignID, adID }) => ({
        url: `/boost/v1/${userID}/campaign/${campaignID}/ad/${adID}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['campaigns'],
    }),
    putAdStatus: build.mutation({
      query: ({ userID, campaignID, adID, status, message, superadmin }) => ({
        url: `/boost/v1/${userID}/campaign/${campaignID}/ad/${adID}/status`,
        method: 'PUT',
        body: {
          status,
          message,
        },
        headers: {
          superadmin,
        },
      }),
      invalidatesTags: ['campaigns'],
    }),
  }),
});

export const {
  useGetBudgetQuery,
  // usePostBudgetMutation,
  usePostSuggestedBudgetMutation,
  usePostPaymentMutation,
  useGetTransactionsQuery,
  usePostImgUrlMutation,
  useGetCampaignsQuery,
  usePostCampaignMutation,
  useGetCampaignQuery,
  usePutCampaignMutation,
  useDeleteCampaignMutation,
  usePutCampaignStatusMutation,
  usePostAdMutation,
  usePutAdMutation,
  useDeleteAdMutation,
  usePutAdStatusMutation,
} = boostApi;

const postCampaignArgsTypes = {
  userID: PropTypes.number.isRequired,
  budgetExternal: PropTypes.number.isRequired,
  endDate: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  ownerID: PropTypes.number.isRequired,
  ownerName: PropTypes.string.isRequired,
  ownerType: PropTypes.oneOf(['Event', 'Season']).isRequired,
  startDate: PropTypes.string.isRequired,
};

const validatePostCampaignArgs = (args) => {
  PropTypes.checkPropTypes(postCampaignArgsTypes, args, 'prop', 'postCampaign');
};

const postAdArgsTypes = {
  userID: PropTypes.number.isRequired,
  campaignID: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  actionCall: PropTypes.string.isRequired,
  squareName: PropTypes.string.isRequired,
  horizontalName: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

const validatePostAdArgs = (args) => {
  PropTypes.checkPropTypes(postAdArgsTypes, args, 'prop', 'postAd');
};

const postSuggestedBudgetArgsTypes = {
  ownerID: PropTypes.number.isRequired,
  ownerType: PropTypes.oneOf(['Event', 'Season']).isRequired,
};

const validatePostSuggestedBudgetArgs = (args) => {
  PropTypes.checkPropTypes(
    postSuggestedBudgetArgsTypes,
    args,
    'prop',
    'postSuggestedBudget',
  );
};
