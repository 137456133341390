import PropTypes from 'prop-types';
import { Box, Button, MobileStepper, Typography } from '@mui/material';
import CloseIcon from '@boletia/blt-components/src/Icons/CloseIcon';
import useLocales from '../../../hooks/useLocales';

const Tooltip = ({
  continuous,
  index,
  step,
  size,
  skipProps,
  backProps,
  primaryProps,
  tooltipProps,
}) => {
  const { translate } = useLocales();

  return (
    <Box
      {...tooltipProps}
      sx={{
        width: { xs: '100%', md: '540px' },
        padding: { xs: '10px 8px 8px 10px', md: '20px 16px 16px 20px' },
        background: '#6E39D3',
        borderRadius: '6px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        gap: '12px',
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        {step.title && (
          <Typography
            sx={{ fontWeight: '600', fontSize: '16px', color: '#fff' }}
          >
            {step.title}
          </Typography>
        )}
        <CloseIcon
          htmlColor="#fff"
          sx={{
            'width': '12px',
            'mt': '-4px',
            '&:hover': { cursor: 'pointer' },
          }}
          id="close"
          {...skipProps}
        />
      </Box>
      <Typography sx={{ color: '#fff' }}>{step.content}</Typography>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <MobileStepper
          variant="dots"
          steps={size}
          position="static"
          activeStep={index}
          sx={{
            'background': 'transparent',
            '& .MuiMobileStepper-dot': {
              background: 'rgba(255, 255, 255, 0.4)',
            },
            '& .MuiMobileStepper-dotActive': {
              background: 'white',
            },
          }}
        />
        <Box sx={{ display: 'flex', gap: '24px' }}>
          {index > 0 && (
            <Button
              variant="text"
              {...backProps}
              id="back"
              sx={{ color: '#fff' }}
            >
              {translate('onboarding.modalButtons.back')}
            </Button>
          )}
          {continuous && (
            <Button
              variant="text"
              {...primaryProps}
              id="next"
              sx={{ color: '#fff' }}
            >
              {translate('onboarding.modalButtons.next')}
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};

Tooltip.propTypes = {
  continuous: PropTypes.bool,
  index: PropTypes.number,
  step: PropTypes.object,
  size: PropTypes.number,
  skipProps: PropTypes.object,
  backProps: PropTypes.object,
  primaryProps: PropTypes.object,
  tooltipProps: PropTypes.object,
};

export default Tooltip;
