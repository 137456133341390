import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { Modal } from '@boletia/blt-components/src/Modal';
import { Message } from '@boletia/blt-components/src/Message';
import { Backdrop, Button, CircularProgress, IconButton } from '@mui/material';
import { Box } from '@mui/system';
import Iconify from '@boletia/blt-ui/src/components/Iconify';
import TagManager from 'react-gtm-module';
import ReservationFormTemplate from '../components/ReservationFormTemplate';
import TicketFormTemplate from '../components/TicketFormTemplate';
import EmptyState from '../components/EmptyState';
import { useGetReservationQuestionsQuery } from '../../../store/api/surveys-api';
import { useGetTicketQuestionsQuery } from '../../../store/api/ticket-surveys-api';
import InitialModal from '../../../components/InitialModal';
import useAuth from '../../../hooks/useAuth';
import EventAlerts from '../../events/components/EventAlerts';
import useEdit from '../../../hooks/useEdit';
import useEvent from '../../../hooks/useEvent';
import NeermeEmptyState from '../components/NeermeEmptyState';

const SuccessModal = ({ open, handleAccpet, text, error }) => (
  <Modal
    color={error ? 'error' : 'primary'}
    open={open}
    confirmation="Aceptar"
    handleAccept={handleAccpet}
  >
    <Message
      title={error ? text : `Las preguntas han sido ${text ?? 'guardadas'}`}
      subtitle={
        error
          ? 'Las modificaciones no se guardaron'
          : 'Presiona aceptar para seguir editando'
      }
      severity={error ? 'error' : 'success'}
      filled
    />
  </Modal>
);

SuccessModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleAccpet: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
};

function Surveys() {
  const [reservationQuestions, setReservationQuestions] = useState([]);
  const [copyReservation, setCopyReservation] = useState([]);
  const [ticketsQuestions, setTicketsQuestions] = useState([]);
  const [copyTickets, setCopyTickets] = useState([]);
  const [isCreatingReservation, setIsCreatingReservation] = useState(false);
  const [isCreatingTicket, setIsCreatingTicket] = useState(false);
  const [surveyType, setSurveyType] = useState('reservation');
  const [ticketTypes, setTicketTypes] = useState([]);
  const [open, setOpen] = useState(false);
  const [text, setText] = useState(null);
  const [error, setError] = useState(false);

  const params = useParams();

  const event = useEvent();

  const { userProfile } = useAuth();
  const { canEdit } = useEdit('editor');
  const userCanEdit = canEdit();

  const { enqueueSnackbar } = useSnackbar();

  const {
    data: reservationData,
    isLoading: isLoadingreservationQuestions,
    isFetching: isFetchingReservation,
    refetch: refetchReservation,
  } = useGetReservationQuestionsQuery(params.event);

  const {
    data: ticketData,
    isLoading: isLoadingTicketData,
    isFetching: isFetchingTicketData,
    refetch: refetchTickets,
  } = useGetTicketQuestionsQuery(params.event);
  const ticketFormFunction = () => {
    if (ticketData?.ticket_types.length > 0) {
      setSurveyType('ticket');
      setIsCreatingTicket(true);
    } else {
      enqueueSnackbar('No tienes boletos creados aún', {
        variant: 'error',
      });
    }
  };

  const reservationFormFunction = () => {
    setSurveyType('reservation');
    setIsCreatingReservation(true);
  };

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'pageview',
        path: `/event/${params.event}/conf/surveys`,
      },
    });
  }, []);

  useEffect(() => {
    if (reservationData !== undefined) {
      if (reservationData.length > 0) {
        setIsCreatingReservation(true);
      }
      setReservationQuestions(
        reservationData.map((res) => {
          const copyRes = { ...res };
          if (copyRes?.options !== '' && copyRes?.options !== undefined) {
            const parsedOptions = JSON.parse(copyRes.options);
            copyRes.options = parsedOptions;
          }
          return copyRes;
        }),
      );
      setCopyReservation(
        reservationData.map((res) => {
          const copyRes = { ...res };
          if (copyRes.options !== '' && copyRes?.options !== undefined) {
            const parsedOptions = JSON.parse(copyRes.options);
            copyRes.options = parsedOptions;
          }
          return copyRes;
        }),
      );
    }
  }, [reservationData]);

  useEffect(() => {
    if (ticketData !== undefined) {
      if (ticketData.tickets.length > 0) {
        setIsCreatingTicket(true);
      }
      setTicketTypes(ticketData.ticket_types);
      setTicketsQuestions(
        ticketData.tickets.map((res) => {
          const copyRes = { ...res };
          if (copyRes.options !== '') {
            const parsedOptions = JSON.parse(copyRes.options);
            copyRes.options = parsedOptions;
          }
          return copyRes;
        }),
      );
      setCopyTickets(
        ticketData.tickets.map((res) => {
          const copyRes = { ...res };
          if (copyRes.options !== '') {
            const parsedOptions = JSON.parse(copyRes.options);
            copyRes.options = parsedOptions;
          }
          return copyRes;
        }),
      );
    }
  }, [ticketData]);

  const handleAcceptModal = () => {
    setOpen(false);
    setText(null);
    setError(false);
  };

  const handleModal = (value, text, error) => {
    setOpen(value);
    setText(text);
    if (error) setError(true);
  };

  const handleCancelReservation = () => {
    setReservationQuestions(copyReservation);
    refetchReservation();
  };

  const handleCancelTickets = () => {
    setTicketsQuestions(copyTickets);
    refetchTickets();
  };

  const [openInitialModal, setOpenInitialModal] = useState(false);

  return (
    <Box>
      <InitialModal
        open={openInitialModal}
        setOpen={setOpenInitialModal}
        title="Encuestas"
        subtitle="Conoce a tu audiencia para crear nuevas experiencias y aliados"
        description="Genera preguntas en el boleto o reserva y obtén información de valor que te ayude a crear nuevas experiencias y alianzas con stakeholders."
      />
      <SuccessModal
        open={open}
        handleAccpet={handleAcceptModal}
        text={text}
        error={error}
      />
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={
          isLoadingreservationQuestions ||
          isLoadingTicketData ||
          isFetchingReservation ||
          isFetchingTicketData
        }
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <EventAlerts
        fiscalAddress={userProfile?.fiscal_address.business_name === null}
      />
      <Box
        sx={{
          display: 'flex',
          gap: '32px',
          marginBottom: '32px',
          paddingLeft: { xs: '10px', md: 0 },
        }}
      >
        <Button
          variant={surveyType === 'reservation' ? 'contained' : 'text'}
          onClick={() => setSurveyType('reservation')}
        >
          Pregunta por reservación
        </Button>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Button
            variant={surveyType === 'ticket' ? 'contained' : 'text'}
            onClick={() => setSurveyType('ticket')}
          >
            Pregunta por boleto
          </Button>
          <IconButton
            sx={{ margin: '0 16px' }}
            onClick={() => setOpenInitialModal(true)}
          >
            <Iconify icon={'ic:baseline-info'} width={20} height={20} />
          </IconButton>
        </Box>
      </Box>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '40px',
          padding: '10px',
        }}
      >
        {surveyType === 'reservation' &&
          !isCreatingReservation &&
          !reservationQuestions.length > 0 && (
            <EmptyState
              formType={surveyType}
              onClick={reservationFormFunction}
              canEdit={userCanEdit}
            />
          )}
        {surveyType === 'ticket' &&
          !isCreatingTicket &&
          event?.channel_type === 'digital' &&
          !ticketsQuestions.length > 0 && <NeermeEmptyState />}
        {surveyType === 'ticket' &&
          !isCreatingTicket &&
          event?.channel_type === 'onsite' &&
          !ticketsQuestions.length > 0 && (
            <EmptyState
              formType={surveyType}
              onClick={ticketFormFunction}
              canEdit={userCanEdit}
            />
          )}
        {surveyType === 'reservation' &&
          isCreatingReservation &&
          !isFetchingReservation && (
            <ReservationFormTemplate
              questionList={reservationQuestions}
              setQuestionList={setReservationQuestions}
              handleModal={handleModal}
              cancelReservationForm={handleCancelReservation}
              canEdit={userCanEdit}
            />
          )}
        {surveyType === 'ticket' &&
          isCreatingTicket &&
          !isFetchingTicketData && (
            <TicketFormTemplate
              questionList={ticketsQuestions}
              setQuestionList={setTicketsQuestions}
              ticketTypes={ticketTypes}
              handleModal={handleModal}
              cancelTicketForm={handleCancelTickets}
              canEdit={userCanEdit}
            />
          )}
      </Box>
    </Box>
  );
}

export default Surveys;
