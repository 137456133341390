import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { setCredentials } from '../slices/auth.slice';

const setAuthHeader = (headers, getState) => {
  const {
    auth,
    config: { client },
  } = getState();
  if (auth) {
    const { idToken, tokenType } = auth;
    if (idToken && tokenType) {
      headers.set('authorization', `${tokenType} ${idToken}`);
    }
  }
  if (client) {
    headers.set('client_id', `${client.id}`);
  }
  return headers;
};

const getAdjustedArgs = (args, preUrl = '', postUrl = '') => {
  const urlEnd = typeof args === 'string' ? args : args.url;
  const adjustedUrl = `${preUrl}${urlEnd}${postUrl}`;
  return typeof args === 'string' ? adjustedUrl : { ...args, url: adjustedUrl };
};

const authBaseQuery = (params) => {
  const rawBaseQuery = fetchBaseQuery({
    baseUrl: params.baseUrl,
    prepareHeaders: (headers, api) =>
      params.prepareHeaders
        ? params.prepareHeaders(setAuthHeader(headers, api.getState), api)
        : setAuthHeader(headers, api.getState),
  });

  const prepareUrlDefault = (state, endpoint, url) => url;

  const authResponseBaseQuery = async (args, api, extraOptions) => {
    const state = api.getState();
    const { auth } = state;
    const urlEnd = typeof args === 'string' ? args : args.url;

    if (!auth || !auth.idToken) {
      return {
        error: {
          status: 400,
          statusText: 'Bad Request',
          data: 'No auth received',
        },
      };
    }

    const adjustedUrl = params.prepareUrl
      ? params.prepareUrl(state, api.endpoint, urlEnd)
      : prepareUrlDefault(state, api.endpoint, urlEnd);

    if (adjustedUrl && adjustedUrl.error) {
      return adjustedUrl;
    }

    const adjustedArgs =
      typeof args === 'string' ? adjustedUrl : { ...args, url: adjustedUrl };

    let result = await rawBaseQuery(adjustedArgs, api, extraOptions);

    if (result.error && result.error.status === 401) {
      if (auth.refreshToken) {
        const refreshResult = await rawBaseQuery(
          {
            url: `${process.env.REACT_APP_API_AUTH}/users/auth/refresh-token`,
            method: 'POST',
            body: {
              refresh_token: auth.refreshToken,
              username: auth.user.email,
            },
          },
          api,
          extraOptions,
        );
        if (refreshResult.error) {
          api.dispatch(
            setCredentials({
              user: null,
              token: null,
              roles: [],
              expiresIn: 0,
              idToken: null,
              refreshToken: null,
              tokenType: null,
            }),
          );
        } else {
          api.dispatch(
            setCredentials({
              user: refreshResult.data.User,
              token:
                refreshResult.data.Access.AuthenticationResult?.AccessToken,
              roles: [],
              expiresIn:
                refreshResult.data.Access.AuthenticationResult?.ExpiresIn,
              idToken: refreshResult.data.Access.AuthenticationResult?.IdToken,
              refreshToken:
                refreshResult.data.Access.AuthenticationResult?.RefreshToken,
              tokenType:
                refreshResult.data.Access.AuthenticationResult?.TokenType,
              challengeName: refreshResult.data.Access.ChallengeName,
              challengeParameters:
                refreshResult.data.Access.ChallengeParameters,
              session: refreshResult.data.Access.Session,
            }),
          );
          result = await rawBaseQuery(adjustedArgs, api, extraOptions);
        }
      } else {
        api.dispatch(
          setCredentials({
            user: null,
            token: null,
            roles: [],
            expiresIn: 0,
            idToken: null,
            refreshToken: null,
            tokenType: null,
          }),
        );
      }
    }
    return result;
  };

  return authResponseBaseQuery;
};

export { setAuthHeader, getAdjustedArgs, authBaseQuery };
