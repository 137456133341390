import { createApi } from '@reduxjs/toolkit/query/react';
import { authBaseQuery } from '../utils/utils';

const prepareUrl = (state, endpoint, url) => {
  const { id: eventID } = state.event;

  if (!eventID) {
    return {
      error: {
        status: 400,
        statusText: 'Bad Request',
        data: 'No event ID received',
      },
    };
  }
  if (endpoint === 'resendReservation') {
    return `reporter/preferences/events/${eventID}/${url}`;
  }
  return `reporter/reservations/events/${eventID}/${url}`;
};

export const reservationsApi = createApi({
  reducerPath: 'reservationsApi',
  baseQuery: authBaseQuery({
    baseUrl: process.env.REACT_APP_API_EVENT,
    prepareUrl,
  }),
  refetchOnMountOrArgChange: true,
  tagTypes: ['Reservations'],
  endpoints: (build) => ({
    getReservations: build.query({
      query: ({ page = 1, limit, status }) => {
        const pageParam = page ? `&page=${page}` : '';
        const limitParam = limit ? `&limit=${limit}` : '';
        const statusParam = status ? `&status=${status}` : '';

        return `bookings?load-totals=t${pageParam}${limitParam}${statusParam}`;
      },
    }),
    getBookings: build.mutation({
      query: ({ page = 1, limit, status }) => {
        const pageParam = page ? `&page=${page}` : '';
        const limitParam = limit ? `&limit=${limit}` : '';
        const statusParam = status ? `&status=${status}` : '';

        return {
          url: `bookings?load-totals=t${pageParam}${limitParam}${statusParam}`,
          method: 'GET',
        };
      },
    }),
    getBooking: build.query({
      query: (id) => `bookings/${id}`,
    }),
    sendReservationsReport: build.mutation({
      query: (emailQuery) => ({
        url: `bookings/export${emailQuery && `?email=${emailQuery}`}`,
        method: 'GET',
      }),
    }),
    resendReservation: build.mutation({
      query: ({ email }) => ({
        url: `bookings-export-email`,
        method: 'PATCH',
        body: { Email: email },
      }),
    }),
  }),
});

export const {
  useGetReservationsQuery,
  useGetBookingsMutation,
  useGetBookingQuery,
  useSendReservationsReportMutation,
  useResendReservationMutation,
} = reservationsApi;
