import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

const seatsAdapter = createEntityAdapter();

const slice = createSlice({
  name: 'seats',
  initialState: seatsAdapter.getInitialState(),
  reducers: {
    upsertOne: seatsAdapter.upsertOne,
    seatRemove: seatsAdapter.removeOne,
    seatRemoveAll: seatsAdapter.removeAll,
  },
});

export const { upsertOne, seatRemove, seatRemoveAll } = slice.actions;

const simpleSelectors = seatsAdapter.getSelectors();

export default slice.reducer;

export const useSeatsIds = () => useSelector((state) => state.seats.ids);
export const useSeatsEntities = () => useSelector((state) => state.seats.entities);

export const useSeats = () => useSelector((state) => state.seats.ids.map((id) => state.seats.entities[id]));

export const useSeatsTotal = () => useSelector((state) => simpleSelectors.selectTotal(state.seats));
