import { createApi } from '@reduxjs/toolkit/query/react';
import { authBaseQuery } from '../utils/utils';

export const userProfileApi = createApi({
  reducerPath: 'userProfileApi',
  baseQuery: authBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_AUTH}/users`,
  }),
  tagTypes: ['userProfile'],
  endpoints: (build) => ({
    getUserProfile: build.query({
      query: (id) => `/user-profile/${id}`,
      providesTags: ['userProfile'],
    }),
    getUsersByIdOrEmail: build.query({
      query: (value) => `/search?filter=${value}`,
      providesTags: ['userSearch'],
      keepUnusedDataFor: 0,
    }),
    getUsers: build.mutation({
      query: (value) => ({
        url: `/search?filter=${value}`,
        method: 'GET',
      }),
      invalidatesTags: ['userSearch'],
    }),
    updateUserProfile: build.mutation({
      query(data) {
        const { id, d } = data;
        return {
          url: `/user-profile/${id}`,
          method: 'PUT',
          body: d,
        };
      },
      invalidatesTags: ['userProfile'],
    }),
    updateMyTaxData: build.mutation({
      query(data) {
        const { id, d } = data;
        return {
          url: `/user-fiscal-address/${id}`,
          method: 'PUT',
          body: d,
        };
      },
      invalidatesTags: ['userProfile'],
    }),
    updateMyBankAccount: build.mutation({
      query: ({ id, d }) => ({
        url: `/user-bank-account/${id}`,
        method: 'PUT',
        body: d,
      }),
      invalidatesTags: ['userProfile'],
    }),
    deleteAccount: build.mutation({
      query: (body) => ({
        url: 'auth/delete',
        method: 'PATCH',
        body,
      }),
    }),
  }),
});

export const {
  useGetUserProfileQuery,
  useGetUsersByIdOrEmailQuery,
  useGetUsersMutation,
  useUpdateUserProfileMutation,
  useUpdateMyTaxDataMutation,
  useUpdateMyBankAccountMutation,
  useDeleteAccountMutation,
} = userProfileApi;
