import PropTypes from 'prop-types';
import { useParams } from 'react-router';
import { useGetRelationshipsQuery } from '../store/api/checkpoints.api';
import RequireParentDialog from '../modules/checkin/components/RequireParentDialog';

export default function RequireParentEvent({ children }) {
  const { event: eventId } = useParams();

  const { data: relationships } = useGetRelationshipsQuery({ eventId });
  const { parent_event_id: parentEventId } = relationships || {
    parent_event_id: '',
  };
  const showDialog = parentEventId && eventId !== parentEventId;
  return showDialog ? (
    <RequireParentDialog parentEventId={parentEventId} />
  ) : (
    children
  );
}

RequireParentEvent.propTypes = {
  children: PropTypes.node.isRequired,
};
