import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  Card,
  Container,
  Divider,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useSettings, Page, useTabs } from '@boletia/blt-ui';
import { Modal } from '@boletia/blt-components/src/Modal';
import { Message } from '@boletia/blt-components/src/Message';
import TagManager from 'react-gtm-module';
import ProfileForm from '../components/myAccount/ProfileForm';
import FiscalForm from '../components/myAccount/FiscalForm';
import BankForm from '../components/myAccount/BankForm';
import PasswordForm from '../components/myAccount/PasswordForm';
import {
  useGetUserProfileQuery,
  useUpdateUserProfileMutation,
  useUpdateMyTaxDataMutation,
  useUpdateMyBankAccountMutation,
} from '../../../store/api/userProfile.api';
import { useResetPasswordMutation } from '../../../store/api/auth.api';
import useAuth from '../../../hooks/useAuth';
import { setCredentials } from '../../../store/slices/auth.slice';
import useUserClient from '../../../hooks/useUserClient';

// ----------------------------------------------------------------------
const InfoModal = ({ showModal, error }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'pageview',
        path: '/dashboard/my-account',
      },
    });
  }, []);
  return (
    <Modal color="primary" open>
      <Message
        title={!error ? 'Contraseña cambiada' : 'Algo salio mal'}
        severity={error ? 'error' : 'success'}
        color={error ? 'error' : 'success'}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
          justifyContent: 'center',
          textAlign: 'center',
        }}
      >
        <Typography variant="label3">
          {!error
            ? 'La contraseña fue cambiada exitosamente'
            : 'La contraseña no ha sido cambiada'}
        </Typography>
        <Typography variant="label3">
          {!error
            ? 'Al dar click en aceptar tendra que volver a iniciar sesión con su nueva contraseña'
            : 'La contraseña que ingreso es incorrecta'}
        </Typography>
        <Button
          onClick={() => {
            if (error) showModal(false);
            else {
              dispatch(
                setCredentials({
                  user: null,
                  token: null,
                  roles: [],
                  expiresIn: 0,
                  idToken: null,
                  refreshToken: null,
                  tokenType: null,
                }),
              );
              navigate('/auth/login');
            }
          }}
        >
          Aceptar
        </Button>
      </Box>
    </Modal>
  );
};

InfoModal.propTypes = {
  showModal: PropTypes.func.isRequired,
  error: PropTypes.bool.isRequired,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

function a11yProps(index) {
  return {
    'id': `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

export default function MyAccountPage() {
  const [searchParams] = useSearchParams();
  const tab = searchParams.get('section');
  const { client } = useUserClient();

  const TabsNoPassword = [
    { value: 'profile', label: 'Mi Perfil' },
    { value: 'fiscal', label: 'Mis datos fiscales' },
    { value: 'bank', label: 'Mi cuenta bancaria' },
  ];

  const TABS = client
    ? TabsNoPassword
    : TabsNoPassword.concat({ value: 'password', label: 'Contraseña' });

  const [showModal, setShowModal] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const { themeStretch } = useSettings();
  const { currentTab, onChangeTab } = useTabs(tab ?? 'profile');

  const { user, auth } = useAuth();

  const { enqueueSnackbar } = useSnackbar();

  const {
    data: profile,
    isFetching,
    isLoading: isProfileLoading,
    refetch,
  } = useGetUserProfileQuery(client?.id ?? user.id);
  const [updateMyProfileData] = useUpdateUserProfileMutation();
  const [updateMyTaxData] = useUpdateMyTaxDataMutation();
  const [updateMyBankAccount] = useUpdateMyBankAccountMutation();
  const [resetPassword, { isLoading: isLoadingResetPassword }] =
    useResetPasswordMutation();

  const handleSubmitUserProfile = async (d) => {
    try {
      await updateMyProfileData({ id: user.id, d }).unwrap();
      enqueueSnackbar('¡Los datos de usuario se han cambiado!');
      refetch();
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmitFiscal = async (d) => {
    try {
      await updateMyTaxData({ id: user.id, d }).unwrap();
      enqueueSnackbar('¡Los datos fiscales se han cambiado!');
      refetch();
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmitBank = async (d) => {
    try {
      await updateMyBankAccount({ id: user.id, d }).unwrap();
      enqueueSnackbar('¡Los datos bancarios se han cambiado!');
      refetch();
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangePassword = async (d) => {
    const data = {
      accessToken: auth.token,
      newPassword: d.newPassword,
      previousPassword: d.actualPassword,
    };
    await resetPassword(data)
      .unwrap()
      .then(() => {
        enqueueSnackbar('¡La constraseña se ha cambiado!');
        setPasswordError(false);
        setShowModal(true);
      })
      .catch(() => {
        setPasswordError(true);
        setShowModal(true);
      });
  };

  return (
    <Page title="Mi Cuenta">
      {showModal && !isLoadingResetPassword && (
        <InfoModal showModal={setShowModal} error={passwordError} />
      )}
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Typography variant="h4" sx={{ marginBottom: '20px' }}>
          Mi Cuenta
        </Typography>
        {!isFetching && !isProfileLoading && (
          <Card>
            <Tabs
              allowScrollButtonsMobile
              variant="scrollable"
              scrollButtons="auto"
              value={currentTab}
              onChange={onChangeTab}
              sx={{ px: 2, bgcolor: 'background.neutral' }}
            >
              {TABS.map((tab, i) => (
                <Tab
                  disableRipple
                  key={tab.value}
                  value={tab.value}
                  label={tab.label}
                  {...a11yProps(i)}
                />
              ))}
            </Tabs>
            <Divider />
            <Box sx={{ padding: '28px 24px 24px 24px' }}>
              <TabPanel value={currentTab} index="profile">
                <ProfileForm
                  value={profile.user_profile}
                  onSubmit={handleSubmitUserProfile}
                />
              </TabPanel>
              <TabPanel value={currentTab} index="fiscal">
                <FiscalForm
                  value={profile.fiscal_address}
                  hasRFC={profile.bank_account.rfc_bank}
                  onSubmit={handleSubmitFiscal}
                />
              </TabPanel>
              <TabPanel value={currentTab} index="bank">
                <BankForm
                  value={profile.bank_account}
                  hasRFC={profile.fiscal_address.rfc}
                  onSubmit={handleSubmitBank}
                />
              </TabPanel>
              {!client && (
                <TabPanel value={currentTab} index="password">
                  <PasswordForm onSubmit={handleChangePassword} />
                </TabPanel>
              )}
            </Box>
          </Card>
        )}
      </Container>
    </Page>
  );
}
