import { configureStore } from '@reduxjs/toolkit';
import { authApi } from './api/auth.api';
import { eventApi } from './api/event.api';
import { userMapsApi } from './api/user-maps.api';
import { ticketTypeApi } from './api/ticket-type.api';
import { holdsApi } from './api/holds.api';
import { holdsApiV2 } from './api/holds-v2.api';
import { billboardApi } from './api/billboard.api';
import { batchApi } from './api/batch.api';
import { invitationsApi } from './api/invitations.api';
import { promotionsApi } from './api/promotions.api';
import { currentEventApi } from './api/current-event.api';
import authReducer from './slices/auth.slice';
import incodeReducer from './slices/incode.slice';
import eventReducer from './slices/event.slice';
import seatsReducer from './slices/seatsio.slice';
import seatsCategoriesReducer from './slices/seats-categories.slice';
import notificationReducer from './slices/notifications.slice';
import configReducer from './slices/config.slice';
import ticketTypeReducer from './slices/ticket-type.slice';
import { userProfileApi } from './api/userProfile.api';
import { paymentMethodsApi } from './api/payment-methods.api';
import { eventSponsorApi } from './api/sponsors.api';
import { surveysApi } from './api/surveys-api';
import { ticketSurveysApi } from './api/ticket-surveys-api';
import { insidersApi } from './api/insiders.api';
import { reservationsApi } from './api/reservations.api';
import { dashboardApi } from './api/dashboard.api';
import {
  localStorageMiddleware,
  localStorageMiddlewareEncrypt,
} from './utils/local-storage';
import { collaboratorsApi } from './api/collaborators.api';
import { preferencesApi } from './api/preferences.api';
import { eventStatisticsApi } from './api/event-statistics.api';
import { onBoardingApi } from './api/onBoarding.api';
import { historyApi } from './api/history.api';
import { npsApi } from './api/nps.api';
import { googleMapsApi } from './api/google-maps.api';
import { checkpointsApi, externalCheckpointsApi } from './api/checkpoints.api';
import { bookingsApi } from './api/bookings.api';
import { banksApi } from './api/banks.api';
import { boostApi } from './api/boost.api';

export default configureStore({
  reducer: {
    auth: authReducer,
    incode: incodeReducer,
    config: configReducer,
    event: eventReducer,
    seats: seatsReducer,
    seatsCategories: seatsCategoriesReducer,
    notification: notificationReducer,
    ticketTypes: ticketTypeReducer,
    [authApi.reducerPath]: authApi.reducer,
    [eventApi.reducerPath]: eventApi.reducer,
    [userMapsApi.reducerPath]: userMapsApi.reducer,
    [ticketTypeApi.reducerPath]: ticketTypeApi.reducer,
    [holdsApi.reducerPath]: holdsApi.reducer,
    [holdsApiV2.reducerPath]: holdsApiV2.reducer,
    [billboardApi.reducerPath]: billboardApi.reducer,
    [batchApi.reducerPath]: batchApi.reducer,
    [invitationsApi.reducerPath]: invitationsApi.reducer,
    [userProfileApi.reducerPath]: userProfileApi.reducer,
    [paymentMethodsApi.reducerPath]: paymentMethodsApi.reducer,
    [eventSponsorApi.reducerPath]: eventSponsorApi.reducer,
    [promotionsApi.reducerPath]: promotionsApi.reducer,
    [surveysApi.reducerPath]: surveysApi.reducer,
    [ticketSurveysApi.reducerPath]: ticketSurveysApi.reducer,
    [insidersApi.reducerPath]: insidersApi.reducer,
    [currentEventApi.reducerPath]: currentEventApi.reducer,
    [reservationsApi.reducerPath]: reservationsApi.reducer,
    [dashboardApi.reducerPath]: dashboardApi.reducer,
    [collaboratorsApi.reducerPath]: collaboratorsApi.reducer,
    [preferencesApi.reducerPath]: preferencesApi.reducer,
    [eventStatisticsApi.reducerPath]: eventStatisticsApi.reducer,
    [onBoardingApi.reducerPath]: onBoardingApi.reducer,
    [historyApi.reducerPath]: historyApi.reducer,
    [npsApi.reducerPath]: npsApi.reducer,
    [googleMapsApi.reducerPath]: googleMapsApi.reducer,
    [checkpointsApi.reducerPath]: checkpointsApi.reducer,
    [externalCheckpointsApi.reducerPath]: externalCheckpointsApi.reducer,
    [bookingsApi.reducerPath]: bookingsApi.reducer,
    [banksApi.reducerPath]: banksApi.reducer,
    [boostApi.reducerPath]: boostApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      localStorageMiddleware(['auth', 'config']),
      localStorageMiddlewareEncrypt(['incode']),
      authApi.middleware,
      userMapsApi.middleware,
      ticketTypeApi.middleware,
      eventApi.middleware,
      holdsApi.middleware,
      holdsApiV2.middleware,
      billboardApi.middleware,
      batchApi.middleware,
      invitationsApi.middleware,
      userProfileApi.middleware,
      paymentMethodsApi.middleware,
      eventSponsorApi.middleware,
      promotionsApi.middleware,
      surveysApi.middleware,
      ticketSurveysApi.middleware,
      insidersApi.middleware,
      currentEventApi.middleware,
      reservationsApi.middleware,
      dashboardApi.middleware,
      collaboratorsApi.middleware,
      preferencesApi.middleware,
      eventStatisticsApi.middleware,
      onBoardingApi.middleware,
      historyApi.middleware,
      npsApi.middleware,
      googleMapsApi.middleware,
      checkpointsApi.middleware,
      externalCheckpointsApi.middleware,
      bookingsApi.middleware,
      banksApi.middleware,
      boostApi.middleware,
    ),
});
