import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Box,
  FormHelperText,
} from '@mui/material';
import Label from '@boletia/blt-ui/src/components/Label';
import { SurveyChip } from './FormDraggableItem';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function MultipleSelect({
  onChange,
  data,
  value,
  error,
  reset,
  label,
  getValue,
  values,
}) {
  const formatTickets = () => {
    let ticketNames = [];
    if (value) {
      ticketNames = value.map((val) => {
        if (typeof val !== 'string') {
          const index = data.findIndex((dat) => dat.id === val);
          return data[index].name;
        }
        return val;
      });
    }
    if (values) {
      ticketNames = values.map((val) => {
        if (typeof val !== 'string') {
          const index = data.findIndex((dat) => dat.id === val);
          return data[index].name;
        }
        return val;
      });
    }
    return ticketNames;
  };
  const [ticketName, setTicketName] = useState(formatTickets() || []);
  const [checkAll, setCheckAll] = useState(false);
  const handleChange = (event) => {
    const val = event.target.value;

    if (val.includes('all')) {
      const tickets = checkAll ? [] : data.map((ticket) => ticket.name);
      setCheckAll(!checkAll);
      setTicketName([...tickets]);
    } else {
      setTicketName(typeof val === 'string' ? val.split(',') : val);
    }
  };

  useEffect(() => {
    onChange(ticketName);
    getValue(ticketName);
    if (data.length === ticketName.length) {
      setCheckAll(true);
    } else setCheckAll(false);
  }, [ticketName]);

  useEffect(() => {
    if (reset) setTicketName([]);
  }, [reset]);

  return (
    <>
      <FormControl sx={{ width: '100%' }}>
        <InputLabel id="demo-multiple-name-label">{label}</InputLabel>
        <Select
          sx={{ width: '100%' }}
          labelId="multiple-checkbox-label"
          id="multiple-checkbox"
          input={<OutlinedInput label={label} />}
          multiple
          value={ticketName}
          onChange={handleChange}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <SurveyChip key={value} label={value} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          <MenuItem value="all">
            <Checkbox checked={checkAll} />
            <ListItemText primary="Todos" />
          </MenuItem>
          {data?.map((ticket) => (
            <MenuItem key={ticket.id} value={ticket.name}>
              <Checkbox checked={ticketName.indexOf(ticket.name) > -1} />
              <ListItemText primary={ticket.name} />
              <Label
                variant="ghost"
                color={
                  ticket.status === 'activo' || ticket.status === 'active'
                    ? 'success'
                    : 'error'
                }
              >
                {ticket.status}
              </Label>
            </MenuItem>
          ))}
        </Select>
        <FormHelperText error={error}>{error && error?.message}</FormHelperText>
      </FormControl>
    </>
  );
}

MultipleSelect.propTypes = {
  data: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any.isRequired,
  reset: PropTypes.bool.isRequired,
  getValue: PropTypes.func,
  values: PropTypes.array,
};

MultipleSelect.defaultProps = {
  getValue: () => {},
  values: [],
};

export default MultipleSelect;
