import { createApi } from '@reduxjs/toolkit/query/react';
import { authBaseQuery } from '../utils/utils';

export const eventSponsorApi = createApi({
  reducerPath: 'userSponsorsApi',
  baseQuery: authBaseQuery({
    baseUrl: process.env.REACT_APP_API_EVENT,
  }),
  tagTypes: ['sponsors'],
  endpoints: (build) => ({
    getEventSponsors: build.query({
      query: (eventId) => ({
        url: `/events/${eventId}/sponsors`,
      }),
      providesTags: ['sponsors'],
    }),
    createEventSponsor: build.mutation({
      query(data) {
        const { eventId, sponsor } = data;
        return {
          url: `/events/${eventId}/sponsors`,
          method: 'POST',
          body: sponsor,
        };
      },
      invalidatesTags: ['sponsors'],
    }),
    deleteEventSponsor: build.mutation({
      query(data) {
        const { eventId, sponsorId } = data;
        return {
          url: `/events/${eventId}/sponsors`,
          method: 'DELETE',
          body: sponsorId,
        };
      },
      invalidatesTags: ['sponsors'],
    }),
  }),
});

export const {
  useGetEventSponsorsQuery,
  useCreateEventSponsorMutation,
  useDeleteEventSponsorMutation,
} = eventSponsorApi;
