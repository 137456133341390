import React, { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { FormsWrapper } from '@boletia/blt-components/src/Forms';
import { Box, Button, Grid, IconButton, InputAdornment } from '@mui/material';
import { RHFTextField } from '@boletia/blt-ui/src/components/hook-form';
import Iconify from '@boletia/blt-ui/src/components/Iconify';
import useLocales from '../../../../hooks/useLocales';
import RegisterPasswordRules from '../../../../sections/auth/components/RegisterPasswordRules';

function PasswordForm({ onSubmit }) {
  const { translate } = useLocales();

  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [showPassword3, setShowPassword3] = useState(false);
  const [password, setPassword] = useState('');
  const [isValid, setIsValid] = useState(false);

  const schema = yup.object({
    newPassword: yup
      .string()
      .matches(/^.{8,}$/, translate('signup.signupForm.validPasswordLength'))
      .matches(
        /(?=.*[A-Z])/,
        translate('signup.signupForm.validPasswordCapital'),
      )
      .matches(/(?=.*\d)/, translate('signup.signupForm.validPasswordNumber'))
      .matches(
        /(?=.*[-+_!@#$%^&*.,?])/,
        translate('signup.signupForm.validPasswordSpecial'),
      )
      .required('Necesitas poner una nueva contraseña'),
    confirmNewpassword: yup
      .string()
      .oneOf(
        [yup.ref('newPassword'), null],
        'Las contraseñas deben ser iguales',
      )
      .required('Confirma tu contraseña'),
    actualPassword: yup
      .string()
      .required('Ingresa tu contraseña para poder realizar los cambios'),
  });

  const validatePartialData = (data) => {
    schema
      .validate(data)
      .then(() => {
        setIsValid(true);
      })
      .catch(() => {
        setIsValid(false);
      });
  };

  const onChange = (data) => {
    setPassword(data.newPassword);
    validatePartialData(data);
  };

  return (
    <FormsWrapper
      onChange={onChange}
      onSubmit={(d) => {
        onSubmit(d);
      }}
      resolver={yupResolver(schema)}
    >
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <Box>
            <RHFTextField
              name="newPassword"
              label="Nueva Contraseña (Déjala en blanco si no vas a cambiarla)"
              type={showPassword1 ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword1(!showPassword1)}
                      edge="end"
                    >
                      <Iconify
                        icon={
                          showPassword1 ? 'eva:eye-fill' : 'eva:eye-off-fill'
                        }
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              helperText=""
            />
            <RegisterPasswordRules password={password} />
          </Box>
        </Grid>
        <Grid item md={6} xs={12}>
          <RHFTextField
            name="confirmNewpassword"
            label="Confirmación de contraseña"
            type={showPassword2 ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword2(!showPassword2)}
                    edge="end"
                  >
                    <Iconify
                      icon={showPassword2 ? 'eva:eye-fill' : 'eva:eye-off-fill'}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <RHFTextField
            name="actualPassword"
            label="Contraseña actual"
            type={showPassword3 ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword3(!showPassword3)}
                    edge="end"
                  >
                    <Iconify
                      icon={showPassword3 ? 'eva:eye-fill' : 'eva:eye-off-fill'}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid
          item
          sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}
        >
          <Button
            type="submit"
            variant="contained"
            sx={{ width: { xs: '100%', md: 'auto' } }}
            size="medium"
            disabled={!isValid}
          >
            Cambiar contraseña
          </Button>
        </Grid>
      </Grid>
    </FormsWrapper>
  );
}

export default PasswordForm;
