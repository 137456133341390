import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Iconify } from '@boletia/blt-ui';
import { IconButton, Typography } from '@mui/material';
import InitialModal from './InitialModal';

export default function EventsControlHeader() {
  const [openInitialModal, setOpenInitialModal] = useState(false);

  const { t } = useTranslation();

  return (
    <>
      <InitialModal
        open={openInitialModal}
        setOpen={setOpenInitialModal}
        title={t('checkin.merge.infoModal.title')}
        subtitle={
          <Typography
            variant="body2"
            component="ul"
            sx={{ textAlign: 'left', m: 4 }}
          >
            <li>{t('checkin.merge.infoModal.features.combineEvents')}</li>
            <li>{t('checkin.merge.infoModal.features.addAccessPoints')}</li>
            <li>{t('checkin.merge.infoModal.features.trackAttendees')}</li>
            <li>{t('checkin.merge.infoModal.features.visualizeStaff')}</li>
          </Typography>
        }
      />
      <Typography
        variant="h5"
        sx={{
          overflow: 'hidden',
          maxHeight: '30px',
          color: 'text.primary',
          textOverflow: 'ellipsis',
          display: 'inline-block',
          whiteSpace: 'nowrap',
        }}
        component="div"
      >
        {t('admin.menu.eventsControl')}
      </Typography>
      <IconButton onClick={() => setOpenInitialModal(true)}>
        <Iconify icon={'ic:baseline-info'} width={20} height={20} />
      </IconButton>
    </>
  );
}
