import { useEffect, useState } from 'react';
import { useGetNpsQuery } from '../store/api/nps.api';
import useAuth from './useAuth';
import useLocalConfig from './useConfig';

const useNpsModal = () => {
  const {
    user: { email },
  } = useAuth();
  const [openNpsModal, setOpenNpsModal] = useState(false);
  const { data, isError, isSuccess } = useGetNpsQuery();
  const { configFreezeNps, setFreezeNps } = useLocalConfig();
  const currentDate = new Date();

  if (
    data?.show_nps &&
    currentDate > configFreezeNps &&
    email &&
    email.indexOf('@boletia') === -1
  ) {
    setTimeout(() => {
      setOpenNpsModal(data?.show_nps);
    }, 300);
  }

  useEffect(() => {
    if (isError) {
      const expiry = new Date();
      expiry.setTime(expiry.getTime() + 24 * 60 * 60 * 1000);
      setFreezeNps(expiry.toString());
    }
    if (isSuccess) {
      setFreezeNps(null);
    }
  }, [isError, isSuccess]);

  return [openNpsModal, setOpenNpsModal];
};
export default useNpsModal;
