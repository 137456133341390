// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
};

export const PATH_EVENT = {
  comingSoon: '/coming-soon',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  events: {
    events: path(ROOTS_DASHBOARD, '/events'),
    eventCreate: path(ROOTS_DASHBOARD, '/event-create'),
  },
  billboards: {
    billboards: path(ROOTS_DASHBOARD, '/billboards'),
    billboardCreate: path(ROOTS_DASHBOARD, '/billboards-create'),
    billboardEdit: path(ROOTS_DASHBOARD, '/billboards-edit'),
  },
};

export const PATH_TC = '/terms-and-conditions';

export const PATH_PP = '/privacy-policy';

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
