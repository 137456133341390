import React from 'react';
import EmptyStateGlobal from '../../../components/EmptyState';

function EmptyState({ formType, onClick, canEdit }) {
  return (
    <EmptyStateGlobal
      onClick={onClick}
      title={`Preguntas por ${
        formType === 'reservation' ? 'reservación' : 'boleto'
      }`}
      desc={
        formType === 'reservation'
          ? 'Crea preguntas personalizadas por cada orden de compra y conoce más a tu audiencia'
          : 'Crea preguntas personalizadas a cada persona y conoce más a tu audiencia'
      }
      label="Agregar pregunta"
      hiddeButton={!canEdit}
    />
  );
}

export default EmptyState;
