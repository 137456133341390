import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from '@boletia/blt-components/src/Modal';
import { Box, Button, IconButton, Typography } from '@mui/material';
import { CloseIcon } from '@boletia/blt-components/src/Icons';
import ModalInfoIcon from '@boletia/blt-components/src/Icons/ModalInfoIcon';
import useLocales from '../hooks/useLocales';

const InitialModal = ({
  icon,
  title,
  subtitle,
  description,
  buttonText,
  open,
  setOpen,
}) => {
  const { translate } = useLocales();
  return (
    <Modal
      color="primary"
      open={open}
      handleClose={() => {
        setOpen(false);
      }}
    >
      <Box
        sx={{
          color: (theme) => theme.palette.grey[900],
          display: 'flex',
          alignItems: 'center',
          width: { xs: 'calc (100% - 32px)', sm: 'calc (100% - 64px)' },
          padding: { xs: '12px 16px', sm: '20px 32px 0px 32px' },
        }}
      >
        <Box sx={{ flexGrow: 1 }} />
        <IconButton onClick={() => setOpen(false)}>
          <CloseIcon sx={{ fontSize: '12px' }} />
        </IconButton>
      </Box>
      <Box
        sx={{
          color: (theme) => theme.palette.primary.main,
          path: {
            fill: (theme) => theme.palette.primary.main,
          },
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: { xs: 'calc (100% - 32px)', sm: 'calc (100% - 64px)' },
          padding: { xs: '12px 16px', sm: '0px 32px 20px 32px' },
        }}
      >
        {icon || (
          <ModalInfoIcon
            sx={{
              fontSize: '60px',
            }}
          />
        )}
      </Box>
      <Box
        sx={{
          textAlign: 'center',
          width: { xs: 'calc (100% - 32px)', sm: 'calc (100% - 64px)' },
          padding: { xs: '12px 16px', sm: '0px 32px 20px 32px' },
        }}
      >
        <Typography variant="h6">{title}</Typography>
        {typeof subtitle === 'string' ? (
          <Typography
            variant="body2"
            sx={{ marginBottom: '18px', fontWeight: '600' }}
          >
            {subtitle}
          </Typography>
        ) : (
          subtitle
        )}

        <Typography variant="body2">{description}</Typography>
      </Box>
      <Box
        sx={{
          textAlign: 'center',
          width: { xs: 'calc (100% - 32px)', sm: 'calc (100% - 64px)' },
          padding: { xs: '12px 16px', sm: '0px 32px 20px 32px' },
        }}
      >
        <Button variant="contained" onClick={() => setOpen(false)}>
          {buttonText || translate('global.buttons.understood')}
        </Button>
      </Box>
    </Modal>
  );
};

InitialModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  title: PropTypes.string,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  description: PropTypes.string,
  buttonText: PropTypes.string,
  icon: PropTypes.node,
};

export default InitialModal;
