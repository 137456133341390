import useResponsive from '@boletia/blt-ui/src/hooks/useResponsive';
import { useMemo } from 'react';
import Joyride from 'react-joyride';
import StepperModal from '../modules/Modals/StepperModal';
import Tooltip from '../modules/onboarding/components/Tooltip';
import useLocalConfig from './useConfig';

const options = {
  zIndex: 1200,
  arrowColor: '#6E39D3',
};

export default function useTour(
  steps,
  storageKey,
  disableScrolling,
  updateTourStatus = () => {},
  userId = 0,
) {
  const { configTour, setTour } = useLocalConfig();
  const run = configTour[storageKey];
  const isDesktop = useResponsive('up', 'lg');
  const callBack = ({ action }) => {
    if (action === 'skip' || action === 'reset') {
      setTour({ [storageKey]: false });
      if (storageKey === 'event') {
        updateTourStatus(userId);
      }
    }
  };
  const tour = useMemo(
    () =>
      isDesktop ? (
        <Joyride
          continuous
          tooltipComponent={Tooltip}
          disableScrolling={disableScrolling}
          run={run}
          steps={steps}
          styles={{ options }}
          callback={callBack}
        />
      ) : (
        <StepperModal steps={steps} run={run} callback={callBack} />
      ),
    [steps, run, isDesktop],
  );
  return tour;
}
