import { useSelector, useDispatch } from 'react-redux';
import {
  setClient as setClientStore,
  clearClient as clearClientStore,
  selectConfigClient,
} from '../store/slices/config.slice';
import { useGetUserProfileQuery } from '../store/api/userProfile.api';

const useUserClient = () => {
  const dispatch = useDispatch();
  const clearClient = () => dispatch(clearClientStore(null));
  const client = useSelector(selectConfigClient);
  const setClient = (id) => {
    dispatch(setClientStore({ id }));
  };

  const { data: clientProfile, isLoading: isClientProfileLoading } =
    useGetUserProfileQuery(client ? client.id : '', {
      skip: !client,
    });

  return {
    clearClient,
    setClient,
    client,
    clientProfile,
    isClientProfileLoading,
  };
};

export default useUserClient;
