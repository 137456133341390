export const esCampaigns = {
  campaigns: {
    campaigns: 'Campañas',
    results: 'Resultado de mis campañas ',
    campaign: 'Campaña',
    balance: 'Saldo disponible',
    recharge: 'Recargar',
    investment: 'Inversión',
    income: 'Ingresos',
    soldTickets: 'Boletos vendidos',
    roas: 'ROAS',
    description:
      '<strong>Promociona tu evento</strong> en miles de páginas web y <strong>aumenta tus ventas</strong>',
    reach: 'Llega a más sitios web, blogs y revistas online.',
    noLimit: '¡No te limites solo a las redes sociales!',
    focus: {
      0: 'Nuestras campañas están enfocadas a:',
      1: '<strong>Buscar nuevas personas</strong> con interés en tu evento.',
      2: '<strong>Encontrar asistentes similares</strong> a quienes ya te compraron en otras plataformas.',
      3: '<strong>Recuperar asistentes interesados</strong> en la página de tu evento.',
    },
    createCampaign: 'Crear campaña',
    createAd: 'Crear anuncio',
    dates: 'Fechas',
    status: 'Estado',
    impressions: 'Impresiones',
    clicks: 'Clics',
    actions: 'Acciones',
    tablePlaceholder: 'Buscar por evento o campaña ...',
    startDate: 'Fecha de inicio',
    endDate: 'Fecha de finalización',
    active: 'Activa',
    finished: 'Finalizada',
    paused: 'Pausada',
    draft: 'En borrador',
    rejected: 'Rechazada',
    stopped: 'Detenida',
    adName: 'Nombre del Anuncio',
    adState: 'Estado del anuncio',
    adCreate: 'Crear nuevo anuncio',
    approved: 'Aprobado',
    investmentTooltip: 'Cantidad de dinero invertida para la campaña',
    incomeTooltip: 'Cantidad de dinero recibida a través de tu campaña',
    soldTicketsTooltip: 'Total de entradas compradas a través de tu campaña',
    roasTooltip:
      'Retorno de la Inversión en Publicidad. Cantidad de dinero obtenido por cada peso invertido',
    draftTooltip: 'Te falta terminar tu campaña',
    activateTooltip: 'Iniciar o pausar campaña',
    sortEndDate: 'Próximo a finalizar',
    sortStartDate: 'Próximo a iniciar',
    sortActive: 'En curso',
    sortCreateDate: 'Fecha de creación',
    sortAscBudget: 'Menor presupuesto',
    sortDescBudget: 'Mayor presupuesto',
    showAll: 'Ver todas',
    default: 'Por defecto',
    create: {
      where: '¿Donde vamos a crear la campaña?',
      billboards: 'Crea campañas para tus carteleras para impulsar sus ventas',
      events: 'Crea campañas para tus eventos y aumenta las ventas',
      title: 'Crea tu campaña',
      description:
        'Aumenta tus ventas creando anuncios liderados por nuestro equipo de marketing en Boletia.',
      name: 'Nombre de la campaña',
      assignBudget: 'Asignar presupuesto',
      min: 'Mínimo',
      enough: 'Suficiente',
      recommended: 'Recomendado',
      exceedsBalance:
        'Tu presupuesto asignado excede el saldo a favor que tienes actualmente, te invitamos a realizar un <strong>Aumento de tu saldo aquí</strong>',
      saveDraft: 'Guardar como borrador',
      addAd: 'Cargar anuncio',
      adDescription:
        'Carga el cartel de tu anuncio y cuéntale a los asistente qué verán.',
      guide: 'Usa la guía para crear tus anuncios',
      downloadGuide: 'Descargar guía',
      square: 'Cuadrado',
      horizontal: 'Horizontal',
      adRule1:
        'Los formatos aceptados son .jpg y .png no deben superar un peso de más de {{maxSize}}',
      adRule2: 'Las medidas aceptadas son de {{width}}x{{height}} px',
      adRule3: 'Los anuncios pasarán por un proceso de revisión',
      eventDescription: 'Describe lo más importante de tu evento',
      maxDescription: 'Máximo 70 caracteres',
      terms:
        'Acepto los <terms>Términos y Condiciones</terms> y las <privacy>Políticas de privacidad</privacy> de Boletia',
      save: 'Guardar y enviar',
      goBack: 'Volver',
      drag: 'Arrastra una imagen de {{width}}X{{height}} pixeles hasta aquí',
      upload: 'Sube un archivo',
      actionCall: 'Llamada a la acción',
      boosted: 'Impulsado por',
      replace: 'Remplazar imagen',
      budgetError: 'Hubo un error al calcular el presupuesto',
      overBudget:
        'Tu presupuesto asignado excede el saldo a favor que tienes actualmente, te invitamos a realizar un <link>Aumento de tu saldo aquí</link>',
      CAMPAIGN_BUDGET_TRANSACTION_NOT_ENOUGH_BALANCE:
        'No hay saldo suficiente para crear el anuncio',
      adError: 'Hubo un error al crear el anuncio',
      adSuccess: 'Anuncio creado con éxito',
      campaignError: 'Hubo un error al crear la campaña',
      campaignSuccess: 'Campaña creada con éxito',
    },
    addBalance: {
      addBalance: 'Agregar saldo',
      title: 'Ingresar información de pago',
      description:
        'Aumenta tu saldo para realizar campañas y lograr aumentar tus ventas',
      amount: 'Escribe aquí cuanto desea recargar',
      cardPayment: 'Pago con tarjeta',
      card: 'Número de tarjeta',
      expiration: 'Expiración MM/AA',
      cvv: 'CVV',
      cardHolder: 'Titular de la tarjeta',
      submit: 'Recargar ahora',
      invoice:
        'Para solicitar factura deberás contactar a tu agente de cuenta de Boletia.',
      seeTransactions: 'Ver ultimas transacciones',
      amountRequired: 'El monto es requerido',
      transactions: 'Resumen de transacciones',
      download: 'Descargar CVS',
      transactionsAmount: 'Monto de la recarga',
      transactionsDate: 'Fecha de la recarga',
    },
    validations: {
      event: 'El evento es requerido',
      billboard: 'La cartelera es requerida',
      campaignName: 'El nombre de la campaña es requerido',
      start: 'La fecha de inicio es requerida',
      end: 'La fecha de finalización es requerida',
      adName: 'El nombre del anuncio es requerido',
      actionCall: 'La llamada a la acción es requerida',
      description: 'La descripción es requerida',
      maxDescription: 'Máximo 70 caracteres',
    },
    superAdmin: {
      organizer: 'Organizador',
      activeCampaigns: 'Campañas activas',
      selectOrganizer: 'Selecciona un organizador',
      manageOrganizer: 'Administración de organizadores',
      noOrganizers: 'Organizador no encontrado',
      organizerData: 'Datos del organizador',
      config: 'Configuraciones',
      activeEvents: 'Eventos activos',
      whitelisted: 'En lista blanca',
      gmv: 'Activar uso de <strong>GMV</strong>',
      balance: 'Saldo',
      campaignsReview: 'Revisión de campañas',
      rejectCampaign: 'Rechazar campaña',
      reject: 'Rechazar',
      ads: 'Anuncios',
      ad: 'Anuncio',
      eventDate: 'Fecha del evento',
      assignedBudget: 'Presupuesto asignado',
      description: 'Descripción',
      approveAd: 'Aprobar anuncio',
    },
  },
};
