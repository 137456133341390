import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Iconify from '@boletia/blt-ui/src/components/Iconify';
import { Box, Modal, Typography } from '@mui/material';
import { Widget } from '@typeform/embed-react';
import useAuth from '../../hooks/useAuth';
import { useUpdateNpsMutation } from '../../store/api/nps.api';
import useLocalConfig from '../../hooks/useConfig';

const TypeFormModal = ({ openNpsModal, setOpenNpsModal }) => {
  const { user } = useAuth();
  const [updateNps, { isSuccess, isError }] = useUpdateNpsMutation();
  const { setFreezeNps } = useLocalConfig();

  const [submitted, setSubmitted] = useState(null);

  const handleClose = () => {
    updateNps();
    setSubmitted(true);
    setTimeout(() => {
      setOpenNpsModal(false);
    }, 2000);
  };

  useEffect(() => {
    if (isError) {
      const expiry = new Date();
      expiry.setTime(expiry.getTime() + 24 * 60 * 60 * 1000);
      setFreezeNps(expiry.toString());
    }
    if (isSuccess) {
      setFreezeNps(null);
    }
  }, [isError, isSuccess]);

  return (
    <Modal
      open={openNpsModal}
      sx={{
        '.MuiBackdrop-root': { background: 'rgba(0, 0, 0, 0.5)' },
      }}
    >
      <Box>
        {!submitted ? (
          <Widget
            hidden={{ user_id: user?.id }}
            id="rw2UHjya"
            keepSession
            onSubmit={() => handleClose()}
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '85%',
              height: '85%',
            }}
            inlineOnMobile
          />
        ) : (
          <Box
            style={{
              backgroundColor: '#0445af',
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '85%',
              height: '85%',
              borderRadius: '8px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              color: '#fff',
              textAlign: 'center',
            }}
          >
            <Box
              style={{
                width: '80%',
                maxWidth: '400px',
              }}
            >
              <Iconify
                icon="material-symbols:check-circle-rounded"
                sx={{ color: '#fff', marginBottom: '48px', fontSize: '106px' }}
              />
              <Typography variant="h4" mb="24px">
                ¡Gracias por tu tiempo y respuesta!
              </Typography>
              <Typography variant="body1">
                Tu participación significa mucho para nosotros.
              </Typography>
              <Typography variant="body1">
                Con tu ayuda, seguiremos mejorando Boletia
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
    </Modal>
  );
};

TypeFormModal.propTypes = {
  openNpsModal: PropTypes.bool.isRequired,
  setOpenNpsModal: PropTypes.func.isRequired,
};

export default TypeFormModal;
