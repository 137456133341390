import { createApi } from '@reduxjs/toolkit/query/react';
import { authBaseQuery } from '../utils/utils';

const prepareHeaders = (headers, api) => {
  if (api.endpoint === 'getGeneralDashboard') {
    const {
      auth: { user: { id: userID } } = { user: {} },
      config: { client },
    } = api.getState();
    headers.set('userId', `${client || userID}`);
  }
  return headers;
};

export const dashboardApi = createApi({
  reducerPath: 'dashboardApi',
  refetchOnMountOrArgChange: true,
  baseQuery: authBaseQuery({
    baseUrl: process.env.REACT_APP_API_EVENT,
    prepareHeaders,
  }),
  tagTypes: ['dashboard'],
  keepUnusedDataFor: 3600,
  endpoints: (build) => ({
    getUTMs: build.query({
      query: (id) => ({
        url: `reporter/events/${id}/utm`,
      }),
    }),
    getEventDashboard: build.query({
      query: (id) => ({
        url: `reporter/dashboard/events/${id}`,
      }),
      providesTags: (result, error, id) => [{ type: 'dashboard', id }],
    }),
    getGeneralDashboard: build.query({
      query: (userId) => ({
        url: `reporter/dashboard`,
      }),
    }),
    getClientDashboard: build.query({
      query: (id) => ({
        url: `/reporter/cube-dashboard/events/${id}/client`,
      }),
    }),
    getSalesDashboard: build.query({
      query: (id) => ({
        url: `/reporter/cube-dashboard/events/${id}/sales`,
      }),
    }),
    getFunnelDashboard: build.query({
      query: (id) => ({
        url: `/reporter/cube-dashboard/events/${id}/funnel`,
      }),
    }),
  }),
});

export const {
  useGetEventDashboardQuery,
  useGetGeneralDashboardQuery,
  useGetClientDashboardQuery,
  useGetSalesDashboardQuery,
  useGetFunnelDashboardQuery,
  useGetUTMsQuery,
} = dashboardApi;
