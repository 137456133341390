import React from 'react';
import PropTypes from 'prop-types';
import FilledInfoIcon from '@boletia/blt-components/src/Icons/FilledInfoIcon';
import { Iconify } from '@boletia/blt-ui';
import { Button, Card, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';

const Spacer = ({ height }) => <Box sx={{ height }} />;

function EmptyState({ onClick, title, desc, desc2, label, icon, hiddeButton }) {
  return (
    <Card sx={{ width: '100%' }}>
      <Grid
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '53px 0 48px 0',
        }}
      >
        <FilledInfoIcon sx={{ width: '53px', height: '53px' }} />
        <Spacer height="17px" />
        <Typography variant="subtitle1">{title}</Typography>

        {desc && (
          <>
            <Spacer height="8px" />
            <Typography sx={{ fontSize: '0.9rem' }}>{desc}</Typography>
          </>
        )}

        {desc2 && (
          <>
            <Spacer height="8px" />
            <Typography sx={{ fontSize: '0.9rem' }}>{desc2}</Typography>
          </>
        )}

        {!hiddeButton && (
          <>
            <Spacer height="32px" />
            <Button variant="contained" color="info" onClick={() => onClick()}>
              {icon && <Iconify icon={icon} width={20} height={20} />}
              {label}
            </Button>
          </>
        )}
      </Grid>
    </Card>
  );
}

EmptyState.propTypes = {
  onClick: PropTypes.func,
  title: PropTypes.string,
  desc: PropTypes.string,
  label: PropTypes.string,
  icon: PropTypes.string,
  hiddeButton: PropTypes.bool,
};

EmptyState.defaultProps = {
  onClick: () => {},
  title: '',
  desc: '',
  label: '',
  icon: '',
};

export default EmptyState;
