import { useNavigate } from 'react-router-dom';
import { Stack, Button } from '@mui/material';
// ----------------------------------------------------------------------

export default function NavbarDocs() {
  const navigate = useNavigate();
  return (
    <Stack
      spacing={3}
      sx={{
        px: 5,
        pb: 5,
        mt: 10,
        width: 1,
        textAlign: 'center',
        display: 'block',
      }}
    >
      <Button variant="contained" onClick={() => navigate('/dashboard/events')}>
        Salir del evento
      </Button>
    </Stack>
  );
}
