import { createApi } from '@reduxjs/toolkit/query/react';
import { authBaseQuery } from '../utils/utils';

const prepareUrl = (state) => {
  const { id: userID } = state.auth.user;
  if (!userID) {
    return {
      error: {
        status: 400,
        statusText: 'Bad Request',
        data: 'ID de usuario no recibido',
      },
    };
  }
  return `/users/nps`;
};

const prepareHeaders = (headers, api) => {
  if (api.endpoint === 'getNps') {
    headers.delete('client_id');
  }
  return headers;
};

export const npsApi = createApi({
  reducerPath: 'npsApi',
  baseQuery: authBaseQuery({
    baseUrl: process.env.REACT_APP_API_AUTH,
    prepareUrl,
    prepareHeaders,
  }),
  tagTypes: ['nps'],
  endpoints: (build) => ({
    getNps: build.query({
      query: () => ({}),
      providesTags: ['nps'],
    }),
    updateNps: build.mutation({
      query() {
        return { method: 'POST' };
      },
      invalidatesTags: ['nps'],
    }),
  }),
});

export const { useGetNpsQuery, useUpdateNpsMutation } = npsApi;
