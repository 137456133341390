import { createApi } from '@reduxjs/toolkit/query/react';
import { authBaseQuery } from '../utils/utils';

export const banksApi = createApi({
  reducerPath: 'banksApi',
  baseQuery: authBaseQuery({
    baseUrl: process.env.REACT_APP_API_EVENT,
  }),
  tagTypes: ['banks'],
  endpoints: (build) => ({
    getBank: build.query({
      query: (id) => `/v1/banks/${id}`,
      providesTags: ['bank'],
    }),
    getBankWithBines: build.query({
      query: (id) => `/v1/banks/${id}/bines?status=active`,
      providesTags: ['bank'],
    }),
    getBanks: build.query({
      query: () => `/v1/banks`,
      providesTags: ['banks'],
    }),
    getBanksWithBines: build.query({
      query: () => `/v1/banks/bines?status=active`,
      providesTags: ['banks'],
    }),
    insertBank: build.mutation({
      query: ({ name, country, code, status }) => ({
        url: `/v1/banks`,
        method: 'POST',
        body: { name, country, code, status },
      }),
      invalidatesTags: ['banks'],
    }),
    updateBank: build.mutation({
      query: ({ id, name, country, code, status }) => ({
        url: `/v1/banks/${id}`,
        method: 'PUT',
        body: { name, country, code, status },
      }),
      invalidatesTags: ['banks'],
    }),
    deleteBank: build.mutation({
      query: (id) => ({
        url: `/v1/banks/${id}/status/deleted`,
        method: 'PATCH',
      }),
    }),
    insertBin: build.mutation({
      query: ({ bankId, cardName, cardType, cardLevel, bin, status }) => ({
        url: `/v1/banks/${bankId}/bines`,
        method: 'POST',
        body: {
          card_name: cardName,
          card_type: cardType,
          card_level: cardLevel,
          bin,
          status,
        },
      }),
      invalidatesTags: ['bank', 'banks'],
    }),
    updateBin: build.mutation({
      query: ({ bankId, id, cardName, cardType, cardLevel, bin, status }) => ({
        url: `/v1/banks/${bankId}/bines/${id}`,
        method: 'PUT',
        body: {
          card_name: cardName,
          card_type: cardType,
          card_level: cardLevel,
          bin,
          status,
        },
        invalidatesTags: ['bank', 'banks'],
      }),
    }),
    deleteBin: build.mutation({
      query: ({ bankId, id }) => ({
        url: `/v1/banks/${bankId}/bines/${id}/status/deleted`,
        method: 'PATCH',
        invalidatesTags: ['bank', 'banks'],
      }),
    }),
    getEventConfig: build.query({
      query: (eventId) => `/v1/banks/events/${eventId}/configuration`,
      providesTags: ['eventConfig'],
    }),
    insertEventConfig: build.mutation({
      query: ({ eventId, body }) => ({
        url: `/v1/banks/events/${eventId}/configuration`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['eventConfig'],
    }),
    putEventConfig: build.mutation({
      query: ({ eventId, body }) => ({
        url: `/v1/banks/events/${eventId}/configuration`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['eventConfig'],
    }),
  }),
});

export const {
  useGetBankQuery,
  useGetBankWithBinesQuery,
  useGetBanksQuery,
  useGetBanksWithBinesQuery,
  useInsertBankMutation,
  useUpdateBankMutation,
  useDeleteBankMutation,
  useInsertBinMutation,
  useUpdateBinMutation,
  useDeleteBinMutation,
  useGetEventConfigQuery,
  useInsertEventConfigMutation,
  usePutEventConfigMutation,
} = banksApi;
