import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

const seatsAdapter = createEntityAdapter({
  selectId: (category) => category.key
});

const slice = createSlice({
  name: 'seatsCategories',
  initialState: seatsAdapter.getInitialState(),
  reducers: {
    seatCategoryAddMany: seatsAdapter.addMany,
    seatCategoryRemoveAll: seatsAdapter.removeAll,
  },
});

export const { seatCategoryAddMany, seatCategoryRemoveAll } = slice.actions;

export default slice.reducer;

export const useSeatsCategoriesIds = () =>
  useSelector((state) => state.seatsCategories.ids);
export const useSeatsCategoriesEntities = () =>
  useSelector((state) => state.seatsCategories.entities);

export const useSeatsCategories = () =>
  useSelector((state) => state.seatsCategories.ids.map((id) => state.seatsCategories.entities[id]));
