import PropTypes from 'prop-types';
import { Box, Typography, useTheme } from '@mui/material';
import { Iconify } from '@boletia/blt-ui';

const RegisterPasswordRules = ({ password }) => {
  const validPasswordLength = password?.match(/.{8,}/);
  const validPasswordUppercase = password?.match(/[A-Z]/);
  const validPasswordNumber = password?.match(/[0-9]/);
  const validPasswordSpecial = password?.match(/[-+_!@#$%^&*.,?]/);

  return (
    password && (
      <Box mt={1}>
        <ValidationText
          text="La contraseña debe tener al menos 8 caracteres"
          valid={validPasswordLength}
        />
        <ValidationText
          text="Debe incluir una letra mayúscula"
          valid={validPasswordUppercase}
        />
        <ValidationText
          text="Debe incluir un número"
          valid={validPasswordNumber}
        />
        <ValidationText
          text="Debe incluir un carácter especial (!@#$%&*-_.)."
          valid={validPasswordSpecial}
        />
      </Box>
    )
  );
};

RegisterPasswordRules.propTypes = {
  password: PropTypes.string,
};

function ValidationText({ text, valid }) {
  const theme = useTheme();
  return (
    <Typography
      display="flex"
      alignItems="center"
      variant="body2"
      sx={{
        color: valid ? theme.palette.success.dark : theme.palette.error.main,
      }}
    >
      <Iconify
        mx={1}
        icon={
          valid
            ? 'material-symbols:check-circle-outline-rounded'
            : 'material-symbols:error-circle-rounded-outline'
        }
      />
      {text}
    </Typography>
  );
}

ValidationText.propTypes = {
  text: PropTypes.string,
  valid: PropTypes.array,
};

export default RegisterPasswordRules;
