import { createApi } from '@reduxjs/toolkit/query/react';
import { authBaseQuery } from '../utils/utils';

const prepareUrl = (state, endpoint, url) => {
  const { id: eventID } = state.event;

  if (!eventID) {
    return {
      error: {
        status: 400,
        statusText: 'Bad Request',
        data: 'No event ID received',
      },
    };
  }

  return `events/${eventID}/${url}`;
};

export const currentEventApi = createApi({
  reducerPath: 'currentEventApi',
  refetchOnMountOrArgChange: true,
  baseQuery: authBaseQuery({
    baseUrl: process.env.REACT_APP_API_EVENT,
    prepareUrl,
  }),
  tagTypes: ['CurrentEvent'],
  keepUnusedDataFor: 3600,
  endpoints: (build) => ({
    validateSubdomain: build.mutation({
      query(subdomain) {
        return {
          url: `subdomain/${subdomain}`,
          method: 'GET',
        };
      },
    }),
  }),
});

export const { useValidateSubdomainMutation } = currentEventApi;
