import React from 'react';
import {
  FormItem,
  FormsWrapper,
  FormText,
} from '@boletia/blt-components/src/Forms';
import { InputMask } from '@boletia/blt-components';
import { FormUploadAvatar } from '@boletia/blt-components/src/Forms/forms-upload';
import { fData } from '@boletia/blt-ui';
import { Box, Button, Card, Grid, Typography } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import useUserClient from '../../../../hooks/useUserClient';

const schema = yup.object({
  first_name: yup.string().required('El nombre es requerido'),
  last_name: yup.string().required('El apellido es requerido'),
  phone_number: yup
    .string()
    .min(10, 'El teléfono debe tener 10 dígitos')
    .max(12, 'El teléfono debe tener 12 dígitos')
    .required('Ingresa tu teléfono')
    .nullable(),
});

function ProfileForm({ value, onSubmit }) {
  const { client } = useUserClient();
  const handleSubmit = (d) => {
    const newData = {
      first_name: d.first_name,
      last_name: d.last_name,
      phone_number: d.phone_number,
    };

    if (typeof d.avatar !== 'string' && d.avatar !== null) {
      newData.avatar = {
        name: d.avatar.name,
        type: d.avatar.type,
        size: d.avatar.size,
        base64: d.avatar.preview,
      };
    }
    onSubmit(newData);
  };

  return (
    <FormsWrapper
      value={value}
      onSubmit={handleSubmit}
      resolver={yupResolver(schema)}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Card>
            <Box sx={{ margin: '40px auto' }}>
              <FormUploadAvatar
                name="avatar"
                accept={{
                  'image/jpeg': [],
                  'image/png': [],
                  'image/jpg': [],
                  'image/gif': [],
                }}
                maxSize={3145728}
                helperText={
                  <Typography
                    variant="caption"
                    sx={{
                      mt: 2,
                      mx: 'auto',
                      display: 'block',
                      textAlign: 'center',
                      color: 'text.secondary',
                    }}
                  >
                    Allowed *.jpeg, *.jpg, *.png, *.gif
                    <br /> max size of {fData(3145728)}
                  </Typography>
                }
              />
            </Box>
          </Card>
        </Grid>
        <Grid item xs={12} md={8}>
          <Card sx={{ padding: { xs: '20px', md: '40px' } }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                gap: '25px',
              }}
            >
              <Grid item md={6} xs={12}>
                <FormText name="first_name" label="Nombres" disabled={client} />
              </Grid>
              <Grid item md={6} xs={12}>
                <FormText
                  name="last_name"
                  label="Apellidos"
                  disabled={client}
                />
              </Grid>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                gap: '25px',
                margin: '32px 0',
              }}
            >
              <Grid item md={6} xs={12}>
                <FormText name="email" disabled label="Correo Electrónico" />
              </Grid>
              <Grid item md={6} xs={12}>
                <FormItem
                  name="phone_number"
                  label="Teléfono"
                  Component={InputMask}
                  componentProps={{
                    disabled: client,
                    size: 'medium',
                    fullWidth: true,
                    label: 'Teléfono',
                    maskProps: {
                      thousandSeparator: true,
                      isNumericString: false,
                      mask: ' ',
                      format: '+## (##) ####-####',
                      allowEmptyFormatting: true,
                    },
                  }}
                />
              </Grid>
            </Box>
            {!client && (
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  type="submit"
                  variant="contained"
                  sx={{ width: { xs: '100%', md: 'auto' } }}
                  size="medium"
                >
                  Guardar Cambios
                </Button>
              </Box>
            )}
          </Card>
        </Grid>
      </Grid>
    </FormsWrapper>
  );
}

export default ProfileForm;
