import { Alert, AlertTitle, Stack, Typography } from '@mui/material';
import { Trans } from 'react-i18next';
import useUserClient from '../../../hooks/useUserClient';

const SuperAdminNotice = () => {
  const { clientProfile } = useUserClient();
  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing="16px"
      sx={{
        backgroundColor: (theme) => theme.palette.warning.lighter,
        color: (theme) => theme.palette.warning.darker,
        borderRadius: '12px',
        padding: '12px 16px',
        border: '1px dashed',
        borderColor: (theme) => theme.palette.warning.darker,
        margin: '20px 0 16px 0',
      }}
    >
      <Alert
        severity="warning"
        sx={{
          padding: 0,
        }}
      >
        <AlertTitle>
          <Typography sx={{ fontSize: '14px' }}>
            <Trans
              i18nKey="superAdmin.managedBy"
              values={{ user: clientProfile?.user_profile?.first_name }}
              components={{ bold: <strong /> }}
            />
          </Typography>
        </AlertTitle>
      </Alert>
    </Stack>
  );
};

export default SuperAdminNotice;
