import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useModals } from '@mattjennings/react-modal-stack';
import { Button } from '@mui/material';

const useUIMessages = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const errors = t('errors', { returnObjects: true });
  const { openModal } = useModals();

  const translateSnackbar = (key, opts = {}, keys = {}) => {
    enqueueSnackbar(t(key, keys), opts);
  };

  const translateServerResponse =
    (
      promise,
      {
        succesKey = 'global.messages.success',
        catchKey = 'global.messages.error',
      } = {},
    ) =>
    (args) =>
      new Promise((resolve, reject) => {
        promise(args)
          .then((resp) => {
            if (resp.error) {
              const msg =
                errors[resp.error.data?.code] || resp.error.data?.message;
              enqueueSnackbar(msg, {
                variant: 'error',
              });
              reject(resp.error);
            } else {
              translateSnackbar(succesKey);
              resolve(resp);
            }
          })
          .catch((er) => {
            translateSnackbar(catchKey, {
              variant: 'error',
            });
            reject(er);
          });
      });

  const translateServerResponseAction =
    (
      promise,
      {
        succesKey = 'global.messages.success',
        catchKey = 'global.messages.error',
        actionKey = 'global.messages.action',
        actionCancelKey = 'global.buttons.cancel',
        actionAcceptKey = 'global.buttons.accept',
        variant = 'warning',
        buttonColor = 'warning',
        tProps = {},
        persist = false,
      } = {},
    ) =>
    (args) =>
      new Promise((resolve, reject) => {
        const acceptHandler = (key) => {
          closeSnackbar(key);
          promise(args)
            .then((resp) => {
              if (resp.error) {
                const msg =
                  errors[resp.error.data?.code] || resp.error.data?.message;
                enqueueSnackbar(msg, {
                  variant: 'error',
                });
                reject(resp.error);
              } else {
                enqueueSnackbar(t(succesKey, tProps));
                resolve(resp);
              }
            })
            .catch((er) => {
              enqueueSnackbar(t(catchKey, tProps), {
                variant: 'error',
              });
              reject(er);
            });
        };
        const ActionButtons = (key) => (
          <>
            <Button
              onClick={() => {
                closeSnackbar(key);
              }}
              color="inherit"
            >
              {t(actionCancelKey)}
            </Button>
            <Button color={buttonColor} onClick={() => acceptHandler(key)}>
              {t(actionAcceptKey)}
            </Button>
          </>
        );

        translateSnackbar(actionKey, {
          action: ActionButtons,
          variant,
          persist,
        });
      });

  const translateServerResponseModal =
    (
      promise,
      {
        succesKey = 'global.messages.success',
        catchKey = 'global.messages.error',
        Modal = () => <></>,
        modalProps = (actionHandler) => ({ actionHandler }),
        tProps = {},
      } = {},
    ) =>
    (args) =>
      new Promise((resolve, reject) => {
        const acceptHandler = () => {
          promise(args)
            .then((resp) => {
              if (resp.error) {
                const msg =
                  errors[resp.error.data?.code] || resp.error.data?.message;
                enqueueSnackbar(msg, {
                  variant: 'error',
                });
                reject(resp.error);
              } else {
                enqueueSnackbar(t(succesKey, tProps));
                resolve(resp);
              }
            })
            .catch((er) => {
              enqueueSnackbar(t(catchKey, tProps), {
                variant: 'error',
              });
              reject(er);
            });
        };

        openModal(Modal, modalProps(acceptHandler));
      });

  return {
    translate: (text, options) => t(text, options),
    translateSnackbar,
    translateServerResponse,
    translateServerResponseAction,
    translateServerResponseModal,
  };
};

export default useUIMessages;
