import { createApi } from '@reduxjs/toolkit/query/react';
import { authBaseQuery } from '../utils/utils';

const prepareUrl = (state, endpoint, url) => {
  if (
    endpoint === 'deleteCollaborator' ||
    endpoint === 'resendEmail' ||
    endpoint === 'getRoles' ||
    endpoint === 'getStaffers'
  ) {
    return url;
  }

  const {
    auth: { user: { id: userID } } = { user: {} },
    config: { client },
  } = state;

  if (!userID) {
    return {
      error: {
        status: 400,
        statusText: 'Bad Request',
        data: 'No user ID received',
      },
    };
  }

  return `users/${client?.id || userID}/${url}`;
};

export const collaboratorsApi = createApi({
  reducerPath: 'collaboratorsApi',
  refetchOnMountOrArgChange: true,
  baseQuery: authBaseQuery({
    baseUrl: process.env.REACT_APP_API_EVENT,
    prepareUrl,
  }),
  tagTypes: ['Collaborators'],
  keepUnusedDataFor: 3600,
  endpoints: (build) => ({
    getCollaborators: build.query({
      query: () => 'my-team',
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: 'Collaborators',
                id,
              })),
              { type: 'Collaborators', id: 'LIST' },
            ]
          : [{ type: 'Collaborators', id: 'LIST' }],
    }),
    addCollaborator: build.mutation({
      query: (body) => ({
        url: `my-team`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'Collaborators', id: 'LIST' }],
    }),
    updateCollaborator: build.mutation({
      query: ({ id, ...patch }) => ({
        url: `my-team`,
        method: 'PUT',
        body: patch,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: 'Collaborators', id },
      ],
    }),
    deleteCollaborator: build.mutation({
      query(id) {
        return {
          url: `users/${id}/my-team`,
          method: 'DELETE',
        };
      },
      invalidatesTags: (result, error, id) => [{ type: 'Collaborators', id }],
    }),
    resendEmail: build.mutation({
      query: ({ id, ...patch }) => ({
        url: `users/${id}/resend-email/my-team`,
        method: 'POST',
        body: patch,
      }),
    }),
    getRoles: build.query({
      query: ({ eventId }) => ({
        url: `users/events/${eventId}`,
      }),
    }),
    getStaffers: build.query({
      query: (data) => ({
        url: data.relationship
          ? `users/events/${data.event}?role=staff&relationship=true`
          : `users/events/${data.event}?role=staff`,
      }),
    }),
  }),
});

export const {
  useGetCollaboratorsQuery,
  useAddCollaboratorMutation,
  useUpdateCollaboratorMutation,
  useDeleteCollaboratorMutation,
  useResendEmailMutation,
  useGetRolesQuery,
  useGetStaffersQuery,
} = collaboratorsApi;

export function useGetCollaboratorQuery(id) {
  const { data, isLoading, error } = useGetCollaboratorsQuery();
  let collaborator = null;
  if (data) {
    collaborator = data.find((user) => user.id === id);
  }

  return {
    data: collaborator,
    isLoading,
    error,
  };
}
