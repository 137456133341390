import PropTypes from 'prop-types';
import { Navigate, useLocation } from 'react-router';
import useAuth from '../hooks/useAuth';

const RequireSuperAdmin = ({ children }) => {
  const {
    user: { super_admin: isSuperAdmin },
  } = useAuth();
  const location = useLocation();

  return isSuperAdmin ? (
    children
  ) : (
    <Navigate to="/dashboard/events" state={{ from: location }} replace />
  );
};

RequireSuperAdmin.propTypes = {
  children: PropTypes.node,
};

export default RequireSuperAdmin;
