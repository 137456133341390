import { createApi } from '@reduxjs/toolkit/query/react';
import { authBaseQuery } from '../utils/utils';

export const ticketSurveysApi = createApi({
  reducerPath: 'ticketSurveysApi',
  baseQuery: authBaseQuery({
    baseUrl: process.env.REACT_APP_API_EVENT,
  }),
  tagTypes: ['ticketSurveys'],
  endpoints: (build) => ({
    getTicketQuestions: build.query({
      query: (eventId) => `/forms/events/${eventId}/forms/ticket`,
      providesTags: ['ticketSurveys'],
      keepUnusedDataFor: 0,
    }),
    createTicketQuestions: build.mutation({
      query(data) {
        const { eventId, questions } = data;
        return {
          url: `/forms/events/${eventId}/forms/ticket`,
          method: 'POST',
          body: questions,
        };
      },
      invalidatesTags: ['ticketSurveys'],
    }),
  }),
});

export const { useGetTicketQuestionsQuery, useCreateTicketQuestionsMutation } =
  ticketSurveysApi;
