import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import {
  ThemeProvider,
  ThemeSettings,
  ScrollToTop,
  ProgressBarStyle,
  MotionLazyContainer,
  NotistackProvider,
} from '@boletia/blt-ui';
import cubejs from '@cubejs-client/core';
import { CubeProvider } from '@cubejs-client/react';
import TagManager from 'react-gtm-module';
import { ModalStack } from '@mattjennings/react-modal-stack';
import { PostHogProvider } from 'posthog-js/react';
import Router from './routes';
import RecaptchaProvider from './components/recaptcha/RecaptchaProvider';
import HelpCenterButton from './components/HelpCenterButton';
import useAuth from './hooks/useAuth';

const TagManagerArgs = {
  gtmId: 'GTM-N6TZDS7',
};

TagManager.initialize(TagManagerArgs);

export default function App() {
  const { auth } = useAuth();

  const cubejsApi = cubejs(
    // auth.token,
    process.env.REACT_APP_CUBE_API_TOKEN,
    {
      apiUrl: process.env.REACT_APP_CUBE_API,
    },
  );

  return (
    <PostHogProvider
      apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
      options={process.env.REACT_APP_PUBLIC_POSTHOG_HOST}
    >
      <MotionLazyContainer>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <ThemeProvider>
            <ThemeSettings>
              <NotistackProvider>
                <ModalStack>
                  <RecaptchaProvider>
                    <CubeProvider cubejsApi={cubejsApi}>
                      <HelpCenterButton />
                      <ProgressBarStyle />
                      <ScrollToTop />
                      <Router />
                    </CubeProvider>
                  </RecaptchaProvider>
                </ModalStack>
              </NotistackProvider>
            </ThemeSettings>
          </ThemeProvider>
        </LocalizationProvider>
      </MotionLazyContainer>
    </PostHogProvider>
  );
}
