import { createApi } from '@reduxjs/toolkit/query/react';
import { authBaseQuery } from '../utils/utils';

const prepareHeaders = (headers, api) => {
  if (api.endpoint === 'updateHold') {
    headers.delete('client_id');
  }
  return headers;
};

export const holdsApiV2 = createApi({
  reducerPath: 'holdsV2',
  baseQuery: authBaseQuery({
    baseUrl: process.env.REACT_APP_API_SEATS,
    prepareHeaders,
  }),
  tagTypes: ['HoldsV2'],
  keepUnusedDataFor: 3600,
  endpoints: (build) => ({
    getHold: build.query({
      query: (holdID) => `/seats/holds/${holdID}`,
      keepUnusedDataFor: 0,
      providesTags: ['HoldsV2'],
    }),
    fetchHold: build.mutation({
      query: (holdID) => ({
        url: `seats/holds/${holdID}`,
        method: 'GET',
      }),
      invalidatesTags: ['HoldsV2'],
    }),
    getEventHolds: build.query({
      query: (eventID) => `/seats/events/${eventID}/holds`,
      keepUnusedDataFor: 0,
      providesTags: (result) =>
        result ? [{ type: 'HoldsV2', id: result.id }] : [],
    }),
    addEventHolds: build.mutation({
      query: ({ name, eventId, details }) => ({
        url: 'seats/holds',
        method: 'POST',
        body: {
          name,
          event_id: eventId,
          details,
        },
      }),
      invalidatesTags: ['HoldsV2'],
    }),
    updateEventHolds: build.mutation({
      query: ({ event, hold, placesToAdd, placesToRemove }) => ({
        url: `seats/events/${event}/holds/${hold}`,
        method: 'PUT',
        body: {
          places_to_add: placesToAdd,
          places_to_remove: placesToRemove,
        },
      }),
      invalidatesTags: ['HoldsV2'],
    }),
    releaseHold: build.mutation({
      query: ({ event, hold }) => ({
        url: `seats/events/${event}/holds/${hold}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['HoldsV2'],
    }),
  }),
});

export const {
  useGetHoldQuery,
  useFetchHoldMutation,
  useGetEventHoldsQuery,
  useAddEventHoldsMutation,
  useUpdateEventHoldsMutation,
  useReleaseHoldMutation,
} = holdsApiV2;
