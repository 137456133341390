import { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectIncodeToken,
  selectInterviewId,
  selectShowIncodeModal,
  selectEventId,
  setIncodeTokenState,
  setInterviewIdState,
  setShowIncodeModal,
  setEventIdState,
} from '../store/slices/incode.slice';

const useIncode = () => {
  const interviewId = useSelector(selectInterviewId);
  const showIncodeModal = useSelector(selectShowIncodeModal);
  const incodeToken = useSelector(selectIncodeToken);
  const incodeEventId = useSelector(selectEventId);

  const dispatch = useDispatch();

  const clearInterviewId = () => {
    dispatch(setInterviewIdState(null));
  };

  const clearIncodeToken = () => {
    dispatch(setIncodeTokenState(null));
  };

  const setInterviewId = (interviewId) => {
    dispatch(setInterviewIdState(interviewId));
  };

  const setIncodeToken = (incodeToken) => {
    dispatch(setIncodeTokenState(incodeToken));
  };

  const setShowIncodeModalValue = (value) => {
    dispatch(setShowIncodeModal(value));
  };

  const setIncodeEventId = (value) => {
    dispatch(setEventIdState(value));
  };

  return useMemo(() => ({
    interviewId,
    showIncodeModal,
    incodeEventId,
    setInterviewId,
    setIncodeEventId,
    clearInterviewId,
    clearIncodeToken,
    setShowIncodeModalValue,
    setIncodeToken,
    incodeToken,
  }));
};

export default useIncode;
