import { createApi } from '@reduxjs/toolkit/query/react';
import { authBaseQuery } from '../utils/utils';

const prepareUrl = (state, endpoint, url) => {
  const { id: eventID } = state.event;

  if (!eventID) {
    return {
      error: {
        status: 400,
        statusText: 'Bad Request',
        data: 'No event ID received',
      },
    };
  }

  return `events/${eventID}/${url}`;
};

export const invitationsApi = createApi({
  reducerPath: 'invitations',
  baseQuery: authBaseQuery({
    baseUrl: process.env.REACT_APP_API_INVITATIOS,
    prepareUrl,
  }),
  tagTypes: ['Invitations'],
  endpoints: (build) => ({
    getInvitations: build.query({
      query: () => 'invitations',
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'Invitations', id })),
              { type: 'Invitations', id: 'LIST' },
            ]
          : [{ type: 'Invitations', id: 'LIST' }],
    }),
    addInvitation: build.mutation({
      query: (body) => ({
        url: `invitations`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'Invitations', id: 'LIST' }],
    }),
  }),
});

export const { useGetInvitationsQuery, useAddInvitationMutation } =
  invitationsApi;
