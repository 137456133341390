import * as React from 'react';
import PropTypes from 'prop-types';
import { useLocation, Navigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';

export const roles = {
  owner: 5,
  partner: 5,
  editor: 4,
  analyst: 2,
  seller: 1,
  insider: 1,
  staff: 1,
};

function RequireRol({ children, rol, exclude }) {
  const { auth } = useAuth();
  const location = useLocation();
  if (roles[auth.roles] >= roles[rol] && auth.roles !== exclude)
    return children;
  return <Navigate to="/dashboard/events" state={{ from: location }} replace />;
}

RequireRol.propTypes = {
  children: PropTypes.node,
  rol: PropTypes.string.isRequired,
  exclude: PropTypes.string,
};

export default RequireRol;
