import { useState } from 'react';
import PropTypes from 'prop-types';
import LocationIcon from '@boletia/blt-components/src/Icons/LocationIcon';
import UrlIcon from '@boletia/blt-components/src/Icons/UrlIcon';
import DocumentIcon from '@boletia/blt-components/src/Icons/DocumentIcon';
import { Box, Link, Popover, Typography } from '@mui/material';
import useResponsive from '@boletia/blt-ui/src/hooks/useResponsive';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useGetEventQuery } from '../../../store/api/event.api';
import { selectAuth } from '../../../store/slices/auth.slice';
import useUserClient from '../../../hooks/useUserClient';

const CustomPopover = ({ text, ...props }) => (
  <Popover
    {...props}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    sx={{
      'div': {
        backgroundColor: 'transparent',
        border: 'none',
        boxShadow: 'none',
        padding: '0 8px 8px',
      },
      '.arrow': {
        padding: 0,
        width: '0px',
        position: 'relative',
        bottom: '100%',
        left: '49.5%',
        marginLeft: '-5px',
        borderWidth: '5px',
        borderStyle: 'solid',
        borderColor: 'transparent transparent #212B36 transparent',
      },
      '.location-box': {
        padding: '4px 8px',
        backgroundColor: (theme) => theme.palette.grey[800],
        borderRadius: '0.5rem',
        display: 'flex',
        alignItems: 'center',
      },
    }}
  >
    <div className="arrow" />
    <Box component="div" className="location-box">
      <Typography
        variant="caption"
        sx={{ fontWeight: 600, color: (theme) => theme.palette.grey[100] }}
      >
        {text}
      </Typography>
    </Box>
  </Popover>
);
CustomPopover.propTypes = {
  text: PropTypes.string.isRequired,
};

const LocationPopover = ({ locationName }) => {
  const [anchorElLocation, setAnchorElLocation] = useState(null);
  const handleClickLocation = (e) => {
    setAnchorElLocation(e.currentTarget);
  };
  const handleCloseLocation = () => {
    setAnchorElLocation(null);
  };
  const openLocation = !!anchorElLocation;
  const idLocation = openLocation ? 'location-popover' : undefined;
  return (
    <>
      <button type="button" onClick={handleClickLocation}>
        <LocationIcon />
      </button>
      <CustomPopover
        id={idLocation}
        open={openLocation}
        anchorEl={anchorElLocation}
        onClose={handleCloseLocation}
        text={locationName}
      />
    </>
  );
};
LocationPopover.propTypes = {
  locationName: PropTypes.string.isRequired,
};

const DocumentPopover = () => {
  const { clientProfile } = useUserClient();

  const {
    user: { contract_state: contractState },
  } = useSelector(selectAuth);

  const contractStates = {
    received: 'Contrato recibido',
    revision: 'Contrato en revisión',
    closed_without_contract: 'Cerrado sin contrato',
    accepted: 'Contrato aceptado',
    not_exist: 'Sin contrato',
    null: 'Sin contrato',
    undefined: 'Sin contrato',
  };

  const contractStatus =
    contractStates[
      clientProfile
        ? clientProfile?.user_profile?.contract_state
        : contractState
    ];

  const hasContractColor = (theme) => theme.palette.success.main;

  const [anchorElDocument, setAnchorElDocument] = useState(null);
  const handleClickDocument = (event) => {
    setAnchorElDocument(event.currentTarget);
  };
  const handleCloseDocument = () => {
    setAnchorElDocument(null);
  };
  const openDocument = !!anchorElDocument;
  const idDocument = openDocument ? 'document-popover' : undefined;
  return (
    <>
      <button type="button" onClick={handleClickDocument}>
        <DocumentIcon
          sx={{
            fill: contractStatus === 'Contrato aceptado' && hasContractColor,
          }}
        />
      </button>
      <CustomPopover
        id={idDocument}
        open={openDocument}
        anchorEl={anchorElDocument}
        onClose={handleCloseDocument}
        text={contractStatus}
      />
    </>
  );
};

const EventDetailsIcons = () => {
  const isMobile = useResponsive('down', 'sm');
  const params = useParams();
  const { data } = useGetEventQuery(params.event);

  return (
    <Box
      sx={{
        'borderTop': isMobile ? '1px solid #E6E8EA' : 'none',
        'padding': isMobile ? '24px 32px 24px 20px' : '0 32px 0 20px',
        'marginTop': isMobile ? '30px' : 0,
        'display': 'flex',
        'justifyContent': 'center',
        'color': (theme) => theme.palette.grey[600],
        '& button': {
          color: (theme) => theme.palette.grey[600],
          border: 'none',
          background: 'transparent',
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
        },
        '& svg': {
          width: isMobile ? '20px' : '16px',
          height: isMobile ? '20px' : '16px',
        },
      }}
    >
      <LocationPopover
        locationName={data?.event_type_subtype?.event_subtype_name || ''}
      />
      <Link
        href={
          window.location.origin.includes('boletia')
            ? `https://${data?.event?.subdomain}.boletia.com/ `
            : `https://${data?.event?.subdomain}.plupets.com/ `
        }
        target="_BLANK"
        rel="noopener noreferrer"
        sx={{
          margin: '0 32px',
          underline: 'none',
          color: 'inherit',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <UrlIcon />
      </Link>
      <DocumentPopover />
    </Box>
  );
};
export default EventDetailsIcons;
