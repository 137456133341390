import PropTypes from 'prop-types';
import { FormItem } from '@boletia/blt-components/src/Forms';
import {
  Grid,
  Box,
  Card,
  Button,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import MultipleSelect from '../MultipleSelect';
import CustomInput from './CustomInput';
import { searchTicketId } from '../Modals/TicketForm';
import useUIMessages from '../../../../hooks/useUIMessages';

const Multiple = ({ name, rules, data, reset, label }) => {
  const { control } = useFormContext();
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, value } }) => (
        <MultipleSelect
          onChange={onChange}
          value={value}
          data={data}
          reset={reset}
          label={label}
        />
      )}
    />
  );
};

Multiple.propTypes = {
  name: PropTypes.string.isRequired,
  rules: PropTypes.object,
  data: PropTypes.array.isRequired,
  reset: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
};

function EditForm({
  type,
  onCancel,
  onSave,
  data,
  index,
  setStartEdit,
  ticketData,
}) {
  const [questionName, setQuestionName] = useState(data.question_title);
  const [helpText, setHelpText] = useState(data.help_text);
  const [options, setOptions] = useState(data.options);
  const [required, setRequired] = useState(data?.required_question);
  const [selected, setSelected] = useState(
    data?.ticket_types
      ?.filter((ticket) => ticketData.find((data) => data.id === ticket))
      .map(
        (ticketType) =>
          ticketData.filter((ticket) => ticket.id === ticketType)[0].name,
      ),
  );

  const { translateSnackbar } = useUIMessages();

  const handleCheck = (event) => {
    setRequired(event.target.checked);
  };

  const handleTickets = (tickets) => {
    setSelected(tickets);
  };

  const handleEdit = () => {
    const editData = {
      question_title: questionName,
      help_text: helpText,
      question_type: data.question_type,
      required_question: required,
    };
    if (data?.ticket_types?.length > 0) {
      editData.ticket_types = searchTicketId(selected, ticketData);
    }
    if (data.question_type === 'select') {
      editData.options = [...options];
    }
    if (data.question_id) {
      editData.question_id = data.question_id;
    }
    setStartEdit(true);
    onSave(editData, index);
    onCancel();
  };

  useEffect(() => {
    if (selected?.length < 1) {
      translateSnackbar('Debes seleccionar al menos un tipo de boleto', {
        variant: 'error',
      });
    }
  }, [selected]);
  return (
    <Card sx={{ padding: '32px 24px 47px 32px', width: '100%', mb: '20px' }}>
      <Grid container alignItems="center" sx={{ width: '100%' }}>
        <Grid item xs={12} sm={8}>
          <CustomInput
            type={type}
            questionName={questionName}
            setQuestionName={setQuestionName}
            helpText={helpText}
            setHelpText={setHelpText}
            options={options}
            setOptions={setOptions}
          />
          {ticketData && (
            <Grid item xs={12} sx={{ mt: 4 }}>
              <FormItem
                name="ticket_types"
                label="Tipos de boleto"
                labelRequired
                Component={MultipleSelect}
                componentProps={{
                  data: ticketData,
                  label: 'Tipos de boleto',
                  getValue: handleTickets,
                  values: data?.ticket_types
                    ?.filter((ticket) =>
                      ticketData.find((data) => data.id === ticket),
                    )
                    .map(
                      (ticketType) =>
                        ticketData.filter(
                          (ticket) => ticket.id === ticketType,
                        )[0].name,
                    ),
                }}
              />
            </Grid>
          )}
          <Grid item md={6} xs={12} sx={{ mt: '22px' }}>
            <FormControlLabel
              control={<Checkbox checked={required} onChange={handleCheck} />}
              label="Marcar como obligatoria"
            />
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          sm={4}
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: '16px',
            }}
          >
            <Button
              onClick={() => {
                onCancel();
              }}
            >
              Cancelar
            </Button>
            <Button
              disabled={
                Array.isArray(options)
                  ? options.length < 1
                  : false || selected?.length < 1
              }
              variant="contained"
              onClick={() => handleEdit()}
            >
              Guardar
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
}

EditForm.propTypes = {
  type: PropTypes.string,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
  data: PropTypes.object,
  index: PropTypes.number,
  setStartEdit: PropTypes.func,
  ticketData: PropTypes.array,
};

export default EditForm;
