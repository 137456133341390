import { memo } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Container, AppBar } from '@mui/material';
import { NavSectionHorizontal } from '@boletia/blt-ui';
import { HEADER } from '@boletia/blt-ui/src/config';

// ----------------------------------------------------------------------

const RootStyle = styled(AppBar)(({ theme }) => ({
  transition: theme.transitions.create('top', {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  width: '100%',
  position: 'fixed',
  zIndex: theme.zIndex.appBar,
  padding: theme.spacing(1, 0),
  boxShadow: theme.customShadows.z8,
  top: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
  backgroundColor: theme.palette.background.default,
}));

// ----------------------------------------------------------------------

CheckinNavbarHorizontal.propTypes = {
  navConfig: PropTypes.any,
};

function CheckinNavbarHorizontal({ navConfig }) {
  return (
    <RootStyle>
      <Container maxWidth={false}>
        <NavSectionHorizontal navConfig={navConfig} />
      </Container>
    </RootStyle>
  );
}

export default memo(CheckinNavbarHorizontal);
