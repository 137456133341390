import { createApi } from '@reduxjs/toolkit/query/react';
import { authBaseQuery } from '../utils/utils';

export const surveysApi = createApi({
  reducerPath: 'surveysApi',
  baseQuery: authBaseQuery({
    baseUrl: process.env.REACT_APP_API_EVENT,
  }),
  tagTypes: ['surveys'],
  endpoints: (build) => ({
    getReservationQuestions: build.query({
      query: (eventId) => `/forms/events/${eventId}/forms/reservation`,
      providesTags: ['surveys'],
    }),
    createReservationQuestions: build.mutation({
      query(data) {
        const { eventId, questions } = data;

        return {
          url: `/forms/events/${eventId}/forms/reservation`,
          method: 'POST',
          body: questions,
        };
      },
      invalidatesTags: ['surveys'],
    }),
  }),
});

export const {
  useGetReservationQuestionsQuery,
  useCreateReservationQuestionsMutation,
} = surveysApi;
