import { enUS, esES, } from '@mui/material/locale';

export const allLangs = [
  {
    label: 'Español',
    value: 'es',
    systemValue: esES,
    icon: 'emojione-v1:flag-for-mexico',
  },
  {
    label: 'English',
    value: 'en',
    systemValue: enUS,
    icon: 'emojione-v1:flag-for-united-states',
  },
];

export const defaultLang = allLangs[0];
