export const adminSteps = {
  es: [
    {
      title: 'Bienvenido al nuevo Boletia',
      content:
        'Este es el lugar indicado para crear y administrar tus eventos.',
      placement: 'center',
      target: 'body',
    },
    {
      title: '¡Crea eventos y experiencias únicas!',
      content:
        'Crea eventos presenciales o en línea, además, consulta y gestiona todos los eventos que hayas creado anteriormente.',
      placement: 'right',
      floaterProps: {
        disabledAnimation: true,
      },
      spotlightPadding: 0,
      target: '.events',
    },
    {
      title: 'Crear un evento es fácil',
      content:
        'Click en el botón crear evento y podrás generar un evento paso a paso. Ingresa la información solicitada y listo!',
      placement: 'right',
      spotlightPadding: 0,
      target: '.create-event',
    },
    {
      title: '¿Tu evento se repite en distintas locaciones o fechas?',
      content:
        'Crea un micrositio o cartelera de venta de los eventos que elijas para tus asistentes. Gestiona, edita, visualiza y elimina carteleras según tus necesidades.',
      placement: 'right',
      spotlightPadding: 0,
      target: '.billboards',
    },
    {
      title: 'Consulta estadísticas importantes sobre tus eventos',
      content:
        'Visualiza toda la información relacionada a las ventas de tus eventos activos como los métodos de pago utilizados, boletos disponibles, vendidos y más.',
      placement: 'right',
      spotlightPadding: 0,
      target: '.statistics',
    },
    {
      title: 'Gestiona tu equipo y optimiza tu operación',
      content:
        'Agrega integrantes a tu equipo y gestiona sus roles y permisos para que tengan acceso a ciertas partes de la administración de tus eventos.',
      placement: 'right',
      spotlightPadding: 0,
      target: '.my-team',
    },
  ],
  en: [
    {
      title: 'Welcome to the new Boletia',
      content: 'This is the place to create and manage your events.',
      placement: 'center',
      target: 'body',
    },
    {
      title: 'Create unique events and experiences!',
      content:
        'Create live events or online events, and manage all the events you have created previously.',
      placement: 'right',
      floaterProps: {
        disabledAnimation: true,
      },
      spotlightPadding: 0,
      target: '.events',
    },
    {
      title: 'Creating an event is easy',
      content:
        'Click on the create event button and you can generate an event step by step. Enter the requested information and you are done!',
      placement: 'right',
      spotlightPadding: 0,
      target: '.create-event',
    },
    {
      title: 'Does your event repeat in different locations or dates?',
      content:
        'Create a microsite or billboard for the events you choose for your attendees. Manage, edit, view and delete billboards according to your needs.',
      placement: 'right',
      spotlightPadding: 0,
      target: '.billboards',
    },
    {
      title: 'Check important statistics about your events',
      content:
        'View all the information related to the sales of your active events such as the payment methods used, available and sold tickets, and more.',
      placement: 'right',
      spotlightPadding: 0,
      target: '.statistics',
    },
    {
      title: 'Manage your team and optimize your operation',
      content:
        'Add members to your team and manage their roles and permissions so that they have access to certain parts of the administration of your events.',
      placement: 'right',
      spotlightPadding: 0,
      target: '.my-team',
    },
  ],
};

export const eventAdminSteps = {
  es: [
    {
      title: 'Este es el administrador de eventos',
      content:
        'Aquí puedes encontrar toda la información respecto al evento que seleccionaste',
      placement: 'center',
      target: 'body',
    },
    {
      title: 'Mantente al tanto de los básicos de tu evento',
      content:
        'Consulta de forma gráfica las estadísticas e información más importante sobre tu evento.',
      placement: 'right',
      spotlightPadding: 0,
      target: '.dashboard',
    },
    {
      title: 'Ajustes',
      content:
        'Crea, gestiona y activa los boletos que estarán disponibles en tu evento. Además elige el modelo de cobro y si necesitas un mapa.',
      placement: 'right',
      spotlightPadding: 0,
      target: '.settings',
    },
    {
      title:
        'Crea los tipos de boleto, elige un modelo de cobro y empieza a vender',
      content:
        'Ajusta detalles y personaliza la página de tu evento, crea tickets para, agrega métodos de pago, genera preguntas para tus asistentes y consulta el mapa para tu evento.',
      placement: 'right',
      spotlightPadding: 0,
      target: '.tickets',
    },
    {
      title: 'Conoce a tu audiencia para crear nuevas experiencias y aliados',
      content:
        'Genera preguntas en el boleto o reserva y obtén información de valor que te ayude a crear nuevas experiencias y alianzas con stakeholders.',
      placement: 'right',
      spotlightPadding: 0,
      target: '.surveys',
    },
    {
      title: 'Visualiza el mapa de tu evento',
      content:
        'Consulta el mapa de tu evento como lo verán los asistentes en nuestra plataforma. Comunícate con nosotros si necesitas esta funcionalidad.',
      placement: 'right',
      spotlightPadding: 0,
      target: '.maps',
    },
    {
      title: 'Genera bloqueos o aparta lugares en tu mapa',
      content:
        'Aparta asientos o zonas en el mapa de tu evento para crear cortesías, vender tu evento por etapas o cualquier otra dinámica que requieras.',
      placement: 'right',
      spotlightPadding: 0,
      target: '.holds',
    },
    {
      title: 'Brinda opciones de pago a tus asistentes',
      content: 'Agrega métodos de pago para tu evento según tus necesidades.',
      placement: 'right',
      spotlightPadding: 0,
      target: '.additional',
    },
    {
      title: 'Genera cortesías y regala una experiencia',
      content:
        'Crea, administra y envía tus cortesías desde aquí. Recuerda generar primero un hold o bloqueo si tienes mapa.',
      placement: 'right',
      spotlightPadding: 0,
      target: '.batches',
    },
    {
      title: 'Maximiza tus ventas con promociones para los asistentes',
      content:
        'Genera múltiples tipos de descuentos y llega a más asistentes. Importante: Sólo podrás editar el límite de usos de tus cupones',
      placement: 'right',
      spotlightPadding: 0,
      target: '.promotions',
    },
    {
      title: 'Conoce y adquiere los servicios adicionales que ofrecemos',
      content:
        'Escríbenos a organizadores@boletia.com para resolver tus dudas (Agrega el ID de tu evento en el título del correo) Duplicar: ¿Tu evento es recurrente, tienes una temporada o muchos horarios? Duplica las veces que sea necesario tu evento con la frecuencia que requieras, sin tener que crear un nuevo evento. Puedes elegir las características que quieres mantener del evento original.',
      placement: 'right',
      spotlightPadding: 0,
      target: '.services',
    },
    {
      title:
        '¿Tu evento es recurrente, tienes una temporada o muchos horarios?',
      content:
        'Duplica las veces que sea necesario tu evento con la frecuencia que requieras, sin tener que crear un nuevo evento. Puedes elegir las características que quieres mantener del evento original.',
      placement: 'right',
      spotlightPadding: 0,
      target: '.duplicate',
    },
    {
      title: 'Reservaciones y obtén las bases de datos',
      content:
        'Visualiza todas las reservaciones que han generado tus asistentes, envía de nuevo sus boletos, consulta detalles y descarga reportes y bases de datos.',
      placement: 'right',
      spotlightPadding: 0,
      target: '.reservations',
    },
    {
      title: 'Consulta las estadísticas importantes sobre tu evento.',
      content:
        'Visualiza toda la información relacionada a las ventas de tu evento como los métodos de pago utilizados, boletos disponibles, vendidos, y más',
      placement: 'right',
      spotlightPadding: 0,
      target: '.event-statistics',
    },
    {
      title: 'Consulta todos los movimientos de tu evento',
      content:
        'Visualiza el histórico de todos los movimientos realizados por tus asistentes en cuanto a ventas y reservaciones.',
      placement: 'right',
      spotlightPadding: 0,
      target: '.history',
    },
  ],
  en: [
    {
      title: 'Welcome to your event dashboard',
      content:
        'Here you can see the most important information about your event. You can also access the most important settings and tools to manage your event.',
      placement: 'center',
      target: 'body',
    },
    {
      title: 'Stay up to date with the basic information of your event',
      content:
        'Consult graphically the most important statistics and information about your event.',
      placement: 'right',
      spotlightPadding: 0,
      target: '.dashboard',
    },
    {
      title: 'Settings',
      content:
        'Create, manage and activate the tickets that will be available at your event. Also choose the payment model and if you need a map.',
      placement: 'right',
      spotlightPadding: 0,
      target: '.settings',
    },
    {
      title:
        'Create the types of tickets, choose a payment model and start selling',
      content:
        'Adjust details and customize the page of your event, create tickets for, add payment methods, generate questions for your attendees and consult the map for your event.',
      placement: 'right',
      spotlightPadding: 0,
      target: '.tickets',
    },
    {
      title: 'Get to know your audience to create new experiences and allies',
      content:
        'Generate questions in the ticket or reservation and get valuable information that will help you create new experiences and alliances with stakeholders.',
      placement: 'right',
      spotlightPadding: 0,
      target: '.surveys',
    },
    {
      title: 'Visualize the map of your event',
      content:
        'Check the map of your event as they will see the attendees in our platform. Contact us if you need this functionality.',
      placement: 'right',
      spotlightPadding: 0,
      target: '.maps',
    },
    {
      title: 'Generate blocks or reserve places on your map',
      content:
        'Reserve seats or areas on the map of your event to create courtesy, sell your event by stages or any other dynamic that you require.',
      placement: 'right',
      spotlightPadding: 0,
      target: '.holds',
    },
    {
      title: 'Provide payment options to your attendees',
      content: 'Add payment methods for your event according to your needs.',
      placement: 'right',
      spotlightPadding: 0,
      target: '.additional',
    },
    {
      title: 'Generate courtesies and give an experience',
      content:
        'Create, manage and send your courtesy from here. Remember to first generate a hold or block if you have a map.',
      placement: 'right',
      spotlightPadding: 0,
      target: '.batches',
    },
    {
      title: 'Maximize your sales with promotions for attendees',
      content:
        'Generate multiple types of discounts and reach more attendees. Important: You can only edit the limit of uses of your coupons',
      placement: 'right',
      spotlightPadding: 0,
      target: '.promotions',
    },
    {
      title: 'Know and acquire the additional services we offer',
      content:
        'Write to  organizadores@boletia.com to resolve your doubts (Add the ID of your event in the title of the email) Duplicate: Is your event recurring, do you have a season or many schedules? Duplicate as many times as necessary your event with the frequency you require, without having to create a new event. You can choose the characteristics you want to keep from the original event.',
      placement: 'right',
      spotlightPadding: 0,
      target: '.services',
    },
    {
      title: 'Is your event recurring, do you have a season or many schedules?',
      content:
        'Duplicate as many times as necessary your event with the frequency you require, without having to create a new event. You can choose the characteristics you want to keep from the original event.',

      placement: 'right',
      spotlightPadding: 0,
      target: '.duplicate',
    },
    {
      title: 'Reservations and get the databases',
      content:
        'View all the reservations that your attendees have generated, send their tickets again, check details and download reports and databases.',
      placement: 'right',
      spotlightPadding: 0,
      target: '.reservations',
    },
    {
      title: 'Check the important statistics about your event.',
      content:
        'View all the information related to the sales of your event such as the payment methods used, available, sold tickets, and more',
      placement: 'right',
      spotlightPadding: 0,
      target: '.event-statistics',
    },
    {
      title: 'Check all the movements of your event',
      content:
        'View the history of all the movements made by your attendees regarding sales and reservations.',
      placement: 'right',
      spotlightPadding: 0,
      target: '.history',
    },
  ],
};
