import { createApi } from '@reduxjs/toolkit/query/react';
import { authBaseQuery } from '../utils/utils';

const prepareUrl = (state, endpoint, url) => {
  const { id: eventID } = state.event;

  if (!eventID) {
    return {
      error: {
        status: 400,
        statusText: 'Bad Request',
        data: 'No event ID received',
      },
    };
  }

  return `events/${eventID}/${url}`;
};

export const promotionsApi = createApi({
  reducerPath: 'promotionsApi',
  refetchOnMountOrArgChange: true,
  baseQuery: authBaseQuery({
    baseUrl: process.env.REACT_APP_API_EVENT,
    prepareUrl,
  }),
  tagTypes: ['Promotions'],
  keepUnusedDataFor: 3600,
  endpoints: (build) => ({
    getPromotions: build.query({
      query: () => 'promotions',
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'Promotions', id })),
              { type: 'Promotions', id: 'LIST' },
            ]
          : [{ type: 'Promotions', id: 'LIST' }],
    }),
    getPromotion: build.query({
      query: (id) => ({
        url: `promotions/${id}`,
      }),
      providesTags: (result, error, id) => [{ type: 'Promotions', id }],
    }),
    addPromotion: build.mutation({
      query: (body) => ({
        url: `promotions`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'Promotions', id: 'LIST' }],
    }),
    updatePromotion: build.mutation({
      query: ({ id, ...patch }) => ({
        url: `promotions/${id}`,
        method: 'PUT',
        body: patch,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'Promotions', id }],
    }),
    updatePromotionStatus: build.mutation({
      query: ({ id, enabled }) => ({
        url: `promotions/${id}/change_status`,
        method: 'PUT',
        body: {
          enabled,
        },
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'Promotions', id }],
    }),
    deletePromotion: build.mutation({
      query(id) {
        return {
          url: `promotions/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: (result, error, id) => [{ type: 'Promotions', id }],
    }),
  }),
});

export const {
  useGetPromotionsQuery,
  useGetPromotionQuery,
  useAddPromotionMutation,
  useUpdatePromotionMutation,
  useUpdatePromotionStatusMutation,
  useDeletePromotionMutation,
} = promotionsApi;
