import React, { useState } from 'react';
import {
  Box,
  Alert,
  Typography,
  Collapse,
  AlertTitle,
  Link,
} from '@mui/material';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import useEvent from '../../../hooks/useEvent';

export default function EventAlerts({
  statistics = false,
  holds = false,
  fiscalAddress = false,
  tickets = false,
  paymentMethods = false,
}) {
  const [open, setOpen] = useState(true);
  const location = useLocation();
  const isInEvent = location.pathname.match(/event\b/g);
  const currentEvent = useEvent();
  return (
    open && (
      <>
        {(statistics ||
          fiscalAddress ||
          tickets ||
          holds ||
          paymentMethods ||
          (!currentEvent.banner && isInEvent)) && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '12px',
              transition: '0.2s',
              marginBottom: '36px',
            }}
          >
            <Collapse in={open}>
              <Alert severity="warning" onClose={() => setOpen(false)}>
                <AlertTitle>¡Tienes alertas!</AlertTitle>
                <ul>
                  {fiscalAddress && (
                    <li>
                      <Typography variant="caption">
                        Debes completar tus datos fiscales.{' '}
                        <Link
                          component={RouterLink}
                          to="/dashboard/my-account?section=fiscal"
                          style={{ fontWeight: '700', color: '#7A4F01' }}
                        >
                          Agregar datos fiscales
                        </Link>
                      </Typography>
                    </li>
                  )}
                  {(tickets || holds) && (
                    <li>
                      <Typography variant="caption">
                        Debes crear un tipo de entrada para tu evento.{' '}
                        <Link
                          component={RouterLink}
                          to={`/event/${currentEvent.id}/conf/tickets`}
                          style={{ fontWeight: '700', color: '#7A4F01' }}
                        >
                          Crear un boleto
                        </Link>
                      </Typography>
                    </li>
                  )}
                  {paymentMethods && (
                    <li>
                      <Typography variant="caption">
                        Debes elegir un método de pago.{' '}
                        <Link
                          component={RouterLink}
                          to={`/event/${currentEvent.id}/conf/payment-method`}
                          style={{ fontWeight: '700', color: '#7A4F01' }}
                        >
                          Agregar un método de pago
                        </Link>
                      </Typography>
                    </li>
                  )}
                  {!currentEvent.banner && isInEvent && (
                    <li>
                      <Typography variant="caption">
                        Falta agregar un banner a tu evento.{' '}
                        <Link
                          component={RouterLink}
                          to={`/event/${currentEvent.id}/conf/edit`}
                          style={{ fontWeight: '700', color: '#7A4F01' }}
                        >
                          Agregar un banner
                        </Link>
                      </Typography>
                    </li>
                  )}
                  {statistics && !isInEvent && (
                    <li>
                      <Typography variant="caption">
                        ¡Haz que tu evento sea un sold out! Ingresa a la sección
                        insights de tu evento para descubrir una forma de hacer
                        eventos inteligentes.
                      </Typography>
                    </li>
                  )}
                  {statistics && isInEvent && (
                    <li>
                      <Typography variant="caption">
                        ¿Sabes cómo vender mejor tus eventos? da
                        <Link
                          component={RouterLink}
                          to={`/event/${currentEvent.id}/dashboard/sales`}
                          style={{ fontWeight: '700', color: '#7A4F01' }}
                        >
                          {' '}
                          click aquí{' '}
                        </Link>
                        y descubre nuestra nueva funcionalidad Smart events
                      </Typography>
                    </li>
                  )}
                </ul>
              </Alert>
            </Collapse>
          </Box>
        )}
      </>
    )
  );
}
