import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'event',
  initialState: {},
  reducers: {
    setEvent: (state, { payload }) => (payload),
  },
});

export const { setEvent } = slice.actions;

export default slice.reducer;

export const selectCurrentEvent = (state) => state.event;