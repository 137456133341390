import { createApi } from '@reduxjs/toolkit/query/react';
import { authBaseQuery } from '../utils/utils';

export const eventApi = createApi({
  reducerPath: 'eventApi',
  refetchOnMountOrArgChange: true,
  baseQuery: authBaseQuery({
    baseUrl: process.env.REACT_APP_API_EVENT,
  }),
  tagTypes: ['Events', 'EventsCollaborators', 'categories', 'eventSearch'],
  keepUnusedDataFor: 3600,
  endpoints: (build) => ({
    getEvents: build.query({
      query: (filters) => {
        return {
          url: `events${filters?.params ?? ''}`,
          method: 'GET',
          headers: filters?.headers ?? {},
        };
      },
      providesTags: (result) => {
        if (!result || !Array.isArray(result.items)) {
          return [{ type: 'Events', id: 'LIST' }];
        }

        return [
          ...result.items.map(({ id }) => ({ type: 'Events', id })),
          { type: 'Events', id: 'LIST' },
        ];
      },
    }),
    /* 
    This is the query that is being used in the ChangeOwnership.js file
    get the events by the user id used in the search field 
    |
    v
    */
    getEventsByuserId: build.query({
      query: (id) => `events/users/${id}`,
      method: 'GET',
    }),
    // This one is for make events ownership change
    transferEventOwnership: build.mutation({
      query: (data) => ({
        url: `users/${data.user_id}/events/ownership`,
        method: 'POST',
        body: {
          new_owner_email: data.new_owner_email,
          events_ids: data.events_ids,
        },
      }),
    }),
    getEventsByIdOrName: build.query({
      query: ({ value, hasWallet }) => ({
        url: `events/search?filter=${value}`,
        method: 'GET',
        headers: {
          wallet: hasWallet,
        },
      }),
      providesTags: ['eventSearch'],
      keepUnusedDataFor: 0,
    }),
    searchEvent: build.mutation({
      query: ({ value, hasWallet }) => ({
        url: `events/search?filter=${value}`,
        method: 'GET',
        headers: {
          wallet: hasWallet,
        },
      }),
      providesTags: ['eventSearch'],
    }),
    searchWalletEvents: build.query({
      query: ({ page, items, order, filter }) => ({
        url: `/events/search/wallet`,
        method: 'GET',
        headers: {
          page,
          items,
          order,
          filter,
        },
      }),
      providesTags: ['eventSearch'],
    }),
    putWallet: build.mutation({
      query: ({ id }) => ({
        url: `events/${id}/wallet`,
        method: 'PUT',
      }),
    }),
    addEvent: build.mutation({
      query: (body) => ({
        url: `events`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'Events', id: 'LIST' }],
    }),
    getEvent: build.query({
      query: (id) => ({
        url: `events/${id}`,
      }),
      providesTags: (result, error, id) => [{ type: 'Events', id }],
    }),
    getSingleEvent: build.mutation({
      query: (id) => ({
        url: `events/${id}`,
        method: 'GET',
      }),
    }),
    updateEvent: build.mutation({
      query: ({ id, ...patch }) => ({
        url: `events/${id}`,
        method: 'PUT',
        body: patch,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'Events', id }],
    }),
    assignMapEvent: build.mutation({
      query: ({ id, idMap }) => ({
        url: `events/${id}/assign-map/${idMap}`,
        method: 'PUT',
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'Events', id }],
    }),
    unassignMapEvent: build.mutation({
      query: ({ id }) => ({
        url: `events/${id}/assign-map`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'Events', id }],
    }),
    duplicateEvent: build.mutation({
      query: ({ id, ...patch }) => ({
        url: `events/${id}/duplicate`,
        method: 'POST',
        body: patch,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'Events', id }],
    }),
    setMapEvent: build.mutation({
      query: ({ id, useMap }) => ({
        url: `events/${id}/use-map/${useMap}`,
        method: 'PUT',
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'Events', id }],
    }),
    deleteEvent: build.mutation({
      query(id) {
        return {
          url: `events/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: (result, error, id) => [{ type: 'Events', id }],
    }),
    validateSubdomain: build.mutation({
      query(subdomain) {
        return {
          url: `events/0/subdomain/${subdomain}`,
          method: 'GET',
        };
      },
    }),
    getEventsCategories: build.query({
      query: () => ({
        url: `events/categories`,
        method: 'GET',
      }),
      invalidatesTags: ['categories'],
    }),
    getEventsByCollaborator: build.query({
      query: (id) => ({
        url: `events/collaborator/${id}`,
        method: 'GET',
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'EventsCollaborators', id })),
              { type: 'EventsCollaborators', id: 'LIST' },
            ]
          : [{ type: 'EventsCollaborators', id: 'LIST' }],
    }),
    addCapacity: build.mutation({
      query: ({ id, capacity }) => ({
        url: `events/${id}/capacity`,
        method: 'POST',
        body: { capacity },
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'Events', id }],
    }),
    updateCapacity: build.mutation({
      query: ({ id, capacity }) => ({
        url: `events/${id}/capacity`,
        method: 'PUT',
        body: { capacity },
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'Events', id }],
    }),
    updateStatus: build.mutation({
      query: ({ id, status }) => ({
        url: `events/${id}/status`,
        method: 'PATCH',
        body: { status },
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'Events', id }],
    }),
    updateTag: build.mutation({
      query: ({ eventIds, tag }) => ({
        url: `events/tag`,
        method: 'POST',
        body: { events: eventIds, tag },
      }),
    }),
    getCheckinEvents: build.query({
      query: () => ({
        url: `events/checkin-web`,
        method: 'GET',
      }),
      keepUnusedDataFor: 0,
    }),
    setTarget: build.mutation({
      query: ({ id, body }) => ({
        url: `events/${id}/target`,
        method: 'POST',
        body: {
          sales_target: body.sales_target,
          target_date: body.target_date,
          tickets_target: body.tickets_target,
        },
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'Events', id }],
    }),
  }),
});

export const useGetCategories = (section) => {
  const { categories } = eventApi.useGetEventsCategoriesQuery(undefined, {
    selectFromResult: ({ data }) => {
      const formattedResponse = {};
      if (data) {
        data.map((item) => {
          formattedResponse[item.name] = item.Subcategories;
          return item;
        });
      }

      return { categories: formattedResponse };
    },
  });
  return section ? categories[section] : [];
};

export const {
  useGetEventQuery,
  useGetEventsByuserIdQuery,
  useGetSingleEventMutation,
  useGetEventsQuery,
  useGetEventsCategoriesQuery,
  useGetEventsByIdOrNameQuery,
  useSearchEventMutation,
  useSearchWalletEventsQuery,
  usePutWalletMutation,
  useAddEventMutation,
  useUpdateEventMutation,
  useDeleteEventMutation,
  useAssignMapEventMutation,
  useUnassignMapEventMutation,
  useDuplicateEventMutation,
  useSetMapEventMutation,
  useValidateSubdomainMutation,
  useGetEventsByCollaboratorQuery,
  useAddCapacityMutation,
  useUpdateCapacityMutation,
  useUpdateStatusMutation,
  useUpdateTagMutation,
  useGetCheckinEventsQuery,
  useSetTargetMutation,
  useTransferEventOwnershipMutation,
} = eventApi;
