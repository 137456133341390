import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: 'notification',
  initialState: {
    message: '',
    severity: '',
    verticalPos: '',
    horizontalPos: '',
    showNotification: false,
  },
  reducers: {
    setNotification: (state, {payload : { message, severity, verticalPos, horizontalPos, showNotification }}) => ({
      message,
      severity,
      verticalPos,
      horizontalPos,
      showNotification,
    }),
    clearNotification: (state, {payload: {message, severity, verticalPos, horizontalPos, showNotification}}) => ({
      message: '',
      severity: '',
      verticalPos: '',
      horizontalPos: '',
      showNotification: false,
    })
  }
});

export const { setNotification, clearNotification } = slice.actions;

export default slice.reducer;

export const selectNotification = (state) => state.notification;