import { createApi } from '@reduxjs/toolkit/query/react';
import { authBaseQuery } from '../utils/utils';

const prepareUrl = (state, endpoint, url) => {
  const { id: eventID } = state.event;
  if (!eventID) {
    return {
      error: {
        status: 400,
        statusText: 'Bad Request',
        data: 'No event ID received',
      },
    };
  }
  return `/historial/logs/${url}/${eventID}`;
};

export const historyApi = createApi({
  reducerPath: 'history',
  baseQuery: authBaseQuery({
    baseUrl: process.env.REACT_APP_API_EVENT,
    prepareUrl,
  }),
  tagTypes: ['history'],
  keepUnusedDataFor: 0,
  endpoints: (build) => ({
    getHistory: build.query({
      query: (body) => ({
        url: body,
      }),
      invalidatesTags: [{ type: 'history', id: 'LIST' }],
    }),
  }),
});

export const { useGetHistoryQuery } = historyApi;
