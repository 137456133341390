import PropTypes from 'prop-types';
import Iconify from '@boletia/blt-ui/src/components/Iconify';
import { Box, Button, Modal, Typography } from '@mui/material';
import useIncode from '../../hooks/useIncode';
import { useGetOnboardingMutation } from '../../store/api/incode.api';
import useEvent from '../../hooks/useEvent';

const CreateIncodeUserModal = ({ openModal, setOpenModal }) => {
  const {
    setInterviewId,
    setIncodeToken,
    setShowIncodeModalValue,
    setIncodeEventId,
  } = useIncode();
  const [getOnboarding] = useGetOnboardingMutation();

  const { id } = useEvent();

  const fetchOnboardingUrl = async () => {
    const response = await getOnboarding().unwrap();
    return response;
  };

  const showIncodeMobileProcess = async () => {
    try {
      const { success, error, url, interviewId, token } =
        await fetchOnboardingUrl();
      if (success) {
        setIncodeEventId(id);
        setInterviewId(interviewId);
        setIncodeToken(token);
        setShowIncodeModalValue(false);
        window.location.replace(url);
      } else {
        console.log('error', error);
      }
    } catch (e) {
      console.log('e', e);
    }
  };

  return (
    <Modal
      open={openModal}
      onClose={() => {
        setOpenModal(false);
      }}
      sx={{
        '.MuiBackdrop-root': { background: 'rgba(0, 0, 0, 0.5)' },
      }}
    >
      <Box>
        <Box
          style={{
            backgroundColor: '#fff',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '85%',
            height: '85%',
            borderRadius: '8px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            color: '#000',
            textAlign: 'center',
          }}
        >
          <Box
            style={{
              width: '80%',
              maxWidth: '800px',
            }}
          >
            <Iconify
              icon="material-symbols:check-circle-rounded"
              sx={{ color: '#000', marginBottom: '48px', fontSize: '106px' }}
            />
            <Typography variant="h4" mb="24px">
              Con el fin de corroborar que eres tú, procederás a examinar la
              identidad de tus documentos.
            </Typography>

            <Button
              variant="contained"
              onClick={showIncodeMobileProcess}
              sx={{ mb: '8px' }}
            >
              Continuar
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

CreateIncodeUserModal.propTypes = {
  openModal: PropTypes.bool.isRequired,
  setOpenModal: PropTypes.func.isRequired,
};

export default CreateIncodeUserModal;
