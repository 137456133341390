import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation, Navigate } from 'react-router';
import { LoadingScreen } from '@boletia/blt-ui';
import useTester from '../hooks/useTester';

export default function RequireTester({ children, testerType }) {
  const [component, setComponent] = useState();
  const location = useLocation();
  const { isTester, redirectPath } = useTester({ testerType });

  useEffect(() => {
    if (isTester === undefined) {
      setComponent(<LoadingScreen />);
    } else if (isTester) {
      setComponent(children);
    } else {
      setComponent(
        <Navigate to={redirectPath} state={{ from: location }} replace />,
      );
    }
  }, [isTester, children]);

  return component;
}

RequireTester.propTypes = {
  children: PropTypes.node.isRequired,
  testerType: PropTypes.string.isRequired,
};
