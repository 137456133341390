import SvgIcon from '@mui/material/SvgIcon';

export default function CampaignIcon() {
  return (
    <SvgIcon viewBox="3 3 18 18" xmlns="http://www.w3.org/2000/svg">
      <g id="icon/campaign">
        <path d="M17 12.1002C17 12.6525 17.4477 13.1002 18 13.1002H20C20.5523 13.1002 21 12.6525 21 12.1002C21 11.5479 20.5523 11.1002 20 11.1002H18C17.4477 11.1002 17 11.5479 17 12.1002ZM18.4 19.0001C18.8418 19.3314 19.4686 19.2419 19.8 18.8001C20.1314 18.3582 20.0418 17.7314 19.6 17.4001L18 16.2001C17.5582 15.8687 16.9314 15.9582 16.6 16.4001C16.2686 16.8419 16.3582 17.4687 16.8 17.8001L18.4 19.0001ZM16.6008 7.80006C16.9322 8.24189 17.559 8.33143 18.0008 8.00006L19.6008 6.80006C20.0426 6.46869 20.1322 5.84189 19.8008 5.40006C19.4694 4.95823 18.8426 4.86869 18.4008 5.20006L16.8008 6.40006C16.359 6.73143 16.2694 7.35823 16.6008 7.80006ZM7 18.6001C7 19.1524 7.44772 19.6001 8 19.6001C8.55228 19.6001 9 19.1524 9 18.6001V15.6001H10L13.4855 17.6914C14.152 18.0913 15 17.6112 15 16.8339V7.36629C15 6.589 14.152 6.10889 13.4855 6.5088L10 8.6001H5C4.45 8.6001 3.97917 8.79594 3.5875 9.1876C3.19583 9.57927 3 10.0501 3 10.6001V13.6001C3 14.1501 3.19583 14.6209 3.5875 15.0126C3.97917 15.4043 4.45 15.6001 5 15.6001H7V18.6001ZM13 9.1501V15.0501L10.55 13.6001H5V10.6001H10.55L13 9.1501Z" />
      </g>
    </SvgIcon>
  );
}
