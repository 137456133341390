import { createApi } from '@reduxjs/toolkit/query/react';
import { authBaseQuery } from '../utils/utils';

export const batchApi = createApi({
  reducerPath: 'batches',
  baseQuery: authBaseQuery({
    baseUrl: process.env.REACT_APP_API_EVENT,
  }),
  tagTypes: ['Batch'],
  endpoints: (build) => ({
    getBatches: build.query({
      query: (event) => `batches/events/${event}`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'Batch', id })),
              { type: 'Batch', id: 'LIST' },
            ]
          : [{ type: 'Batch', id: 'LIST' }],
    }),
    getBatchesByHold: build.query({
      query: (data) => `batches/events/${data.event}/hold/${data.hold}`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'Batch', id })),
              { type: 'Batch', id: 'LIST' },
            ]
          : [{ type: 'Batch', id: 'LIST' }],
    }),
    createBatch: build.mutation({
      query: ({
        eventID,
        attendeeFirstName,
        holdID,
        isPrintable = false,
        name,
        price,
        ticketTypeId,
        places = [
          {
            hold_detail_id: null,
          },
        ],
      }) => ({
        url: `/batches/events/${eventID}/assign-hold`,
        method: 'POST',
        body: {
          attendee_first_name: attendeeFirstName,
          attendee_last_name: '',
          hold_id: holdID,
          is_printable: isPrintable,
          name,
          places,
          price,
          ticket_type_id: ticketTypeId,
        },
      }),
    }),
  }),
});

export const {
  useGetBatchesQuery,
  useGetBatchesByHoldQuery,
  useCreateBatchMutation,
} = batchApi;
