import * as React from 'react';
import PropTypes from 'prop-types';
import { useLocation, Navigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';

function RequireAuth({ children, validateChangePass }) {
  const { user, auth } = useAuth();
  const location = useLocation();

  if (!user) {
    if (location.pathname === '/auth/change-password') {
      return <Navigate to="/auth/login" state={{ from: null }} replace />;
    }
    return <Navigate to="/auth/login" state={{ from: location }} replace />;
  }
  if (validateChangePass && auth.challengeName === 'NEW_PASSWORD_REQUIRED') {
    return (
      <Navigate to="/auth/change-password" state={{ from: location }} replace />
    );
  }

  return children;
}

RequireAuth.propTypes = {
  children: PropTypes.node,
  validateChangePass: PropTypes.bool,
};

RequireAuth.defaultProps = {
  children: <></>,
  validateChangePass: false,
};

export default RequireAuth;
