import { createApi } from '@reduxjs/toolkit/query/react';
import { authBaseQuery } from '../utils/utils';

export const bookingsApi = createApi({
  reducerPath: 'bookingsApi',
  baseQuery: authBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_AUTH}/bookings`,
  }),
  tagTypes: ['bookings'],
  endpoints: (build) => ({
    cancelBooking: build.mutation({
      query: ({ id, ...body }) => ({
        url: `/cancel/${id}`,
        method: 'POST',
        body,
      }),
    }),
    getBooking: build.query({
      query: (id) => `/${id}`,
    }),
    editBooking: build.mutation({
      query: ({ id, body }) => ({
        url: `/${id}`,
        method: 'PUT',
        body,
      }),
    }),
  }),
});

export const {
  useCancelBookingMutation,
  useGetBookingQuery,
  useEditBookingMutation,
} = bookingsApi;
