import { useState } from 'react';
import { alpha } from '@mui/material/styles';
import {
  Box,
  Divider,
  Typography,
  Stack,
  MenuItem,
  Avatar,
} from '@mui/material';
import { MenuPopover, IconButtonAnimate } from '@boletia/blt-ui';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import useUserClient from '../../hooks/useUserClient';

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'Perfil',
    linkTo: '/dashboard/my-account',
  },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const [open, setOpen] = useState(null);
  const navigate = useNavigate();

  const { profile, logout } = useAuth();

  const { clearClient, client, clientProfile } = useUserClient();

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleLink = (link) => {
    navigate(link);
  };

  const isLocal = window.location.href.includes('localhost');

  const handleLogout = () => {
    window.location.href = `https://blt-domain.auth.us-east-1.amazoncognito.com/logout?client_id=1siba6ngus1m356uk4heef6007&logout_uri=${
      isLocal ? 'http://localhost:3000' : 'https://www.gz.plupets.com'
    }/auth/login`;
    logout();
    clearClient();
    // navigate('/auth/login?status=log-out');
  };

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar
          src={clientProfile?.user_profile?.avatar ?? profile?.avatar}
          alt={profile?.first_name}
        />
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          'p': 0,
          'mt': 1.5,
          'ml': 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {!client
              ? `${profile?.first_name} ${profile?.last_name}`
              : `${clientProfile?.user_profile?.first_name} ${clientProfile?.user_profile?.last_name}`}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {!client ? profile?.email : clientProfile?.user_profile?.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem
              key={option.label}
              to={option.linkTo}
              onClick={() => handleLink(option.linkTo)}
            >
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem sx={{ m: 1 }} onClick={handleLogout}>
          Cerrar sesión
        </MenuItem>
      </MenuPopover>
    </>
  );
}
