import React, { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  FormSelect,
  FormsWrapper,
  FormText,
} from '@boletia/blt-components/src/Forms';
import { Button, Checkbox, FormControlLabel, Grid } from '@mui/material';
import FormModal from './FormModal';

const schema = yup.object({
  question_title: yup
    .string()
    .max(200, 'El titulo no debe superar los 200 caracteres')
    .required('Ingresa una pregunta'),
  help_text: yup
    .string()
    .max(200, 'El texto de ayuda no debe superar los 200 caracteres'),
  question_type: yup.string().required('Selecciona un tipo de pregunta'),
});

function ReservationForm({ value, open, handleModal, onSubmit }) {
  const [required, setRequired] = useState(value?.required_question);

  const handleCheck = (event) => {
    setRequired(event.target.checked);
  };

  const handleSubmit = (data) => {
    const isSelecQuestion = data.question_type === 'select';
    const dataFormatted = {
      help_text: data.help_text,
      question_title: data.question_title,
      question_type: data.question_type,
      position: null,
      required_question: required,
    };
    if (Object.prototype.hasOwnProperty.call(data, 'question_id')) {
      dataFormatted.question_id = data.question_id;
    }
    if (isSelecQuestion) {
      dataFormatted.options = [];
    }
    setRequired(false);
    return dataFormatted;
  };
  return (
    <FormModal
      title="Pregunta de registro por reservación"
      open={open}
      handleModal={handleModal}
    >
      <FormsWrapper
        value={value}
        onSubmit={(d, { reset }) => {
          onSubmit(handleSubmit(d));
          handleModal(false);
          reset();
        }}
        resolver={yupResolver(schema)}
      >
        <Grid
          container
          spacing={4}
          sx={{ padding: { xs: '10px', md: '32px' } }}
        >
          <Grid item md={6} xs={12}>
            <FormText
              name="question_title"
              label="Título de la pregunta"
              labelRequired
              componentProps={{
                fullWidth: true,
              }}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <FormText
              name="help_text"
              label="Texto de ayuda"
              labelDetail="(opcional)"
              componentProps={{
                fullWidth: true,
              }}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <FormSelect name="question_type" label="Tipo de pregunta">
              <option hidden selected>
                Selecciona una opción
              </option>
              <option value="text">Texto</option>
              <option value="text_area">Párrafo</option>
              <option value="select">Seleccionar de una lista</option>
            </FormSelect>
          </Grid>
          <Grid item md={12} xs={12}>
            <FormControlLabel
              control={<Checkbox checked={required} onChange={handleCheck} />}
              label="Marcar como obligatoria"
            />
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            gap: '10px',
            padding: '24px',
            borderTop: '1px solid #EDEFF2',
          }}
        >
          <Button
            variant="outlined"
            onClick={() => {
              handleModal(false);
              setRequired(false);
            }}
          >
            Cancelar
          </Button>
          <Button variant="contained" type="submit">
            Agregar pregunta
          </Button>
        </Grid>
      </FormsWrapper>
    </FormModal>
  );
}

export default ReservationForm;
