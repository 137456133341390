import { createApi } from '@reduxjs/toolkit/query/react';
import { authBaseQuery } from '../utils/utils';

const prepareUrl = (state, endpoint, url) => {
  const { id: userID } = state.auth.user;
  if (!userID) {
    return {
      error: {
        status: 400,
        statusText: 'Bad Request',
        data: 'No event ID received',
      },
    };
  }
  return `/users/${userID}/${url}`;
};

export const onBoardingApi = createApi({
  reducerPath: 'onBoarding',
  baseQuery: authBaseQuery({
    baseUrl: process.env.REACT_APP_API_EVENT,
    prepareUrl,
  }),
  tagTypes: ['onBoarding'],
  keepUnusedDataFor: 3600,
  endpoints: (build) => ({
    getOnboardingStatus: build.query({
      query: (body) => `onboarding-questions`,
      invalidatesTags: [{ type: 'onBoarding', id: 'LIST' }],
    }),
    addQuestions: build.mutation({
      query: (body) => ({
        url: `onboarding-questions`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'onBoarding', id: 'LIST' }],
    }),
    getOnboardingTourStatus: build.query({
      query: (body) => `onboarding-tour`,
      invalidatesTags: [{ type: 'onBoarding', id: 'LIST' }],
    }),
    addOnboardingStatus: build.mutation({
      query: (body) => ({
        url: `onboarding-tour`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'onBoarding', id: 'LIST' }],
    }),
  }),
});

export const {
  useGetOnboardingStatusQuery,
  useAddQuestionsMutation,
  useGetOnboardingTourStatusQuery,
  useAddOnboardingStatusMutation,
} = onBoardingApi;
