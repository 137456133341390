import secureLocalStorage  from  "react-secure-storage";

const localStorageMiddleware =
  (items) =>
  ({ getState }) =>
  (next) =>
  (action) => {
    const result = next(action);
    const currentItem = action.type.split('/')[0];
    if (items.includes(currentItem)) {
      const localState = JSON.parse(localStorage.getItem('blt-storage')) || {};
      const state = getState()[currentItem];
      localStorage.setItem(
        'blt-storage',
        JSON.stringify({
          ...localState,
          [currentItem]: state,
        }),
      );
    }
    return result;
  };

const reHydrateStore = (item) => {
  const localState = JSON.parse(localStorage.getItem('blt-storage')) || {};
  return localState[item];
};


const localStorageMiddlewareEncrypt =
  (items) =>
  ({ getState }) =>
  (next) =>
  (action) => {
    const result = next(action);
    const currentItem = action.type.split('/')[0];
    if (items.includes(currentItem)) {
      const localState = JSON.parse(secureLocalStorage.getItem('blt-enstorage')) || {};
      const state = getState()[currentItem];
      secureLocalStorage.setItem(
        'blt-enstorage',
        JSON.stringify({
          ...localState,
          [currentItem]: state,
        }),
      );
    }
    return result;
  };

const reHydrateStoreEncrypt = (item) => {
  const localState = JSON.parse(secureLocalStorage.getItem('blt-enstorage')) || {};
  return localState[item];
};

export { localStorageMiddleware, reHydrateStore, localStorageMiddlewareEncrypt, reHydrateStoreEncrypt };
