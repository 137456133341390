import { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectCurrentUser,
  setCredentials,
  selectAuth,
} from '../store/slices/auth.slice';
import { useGetUserProfileQuery } from '../store/api/userProfile.api';

const useAuth = () => {
  const user = useSelector(selectCurrentUser);
  const auth = useSelector(selectAuth);

  const dispatch = useDispatch();

  const logout = () => {
    dispatch(
      setCredentials({
        user: null,
        token: null,
        roles: [],
        expiresIn: 0,
        idToken: null,
        refreshToken: null,
        tokenType: null,
      }),
    );
  };

  const {
    data: userProfile,
    isLoading: isUserProfileLoading,
    error,
  } = useGetUserProfileQuery(user ? user.id : '');

  const profile =
    !isUserProfileLoading && userProfile && userProfile.user_profile;

  return useMemo(
    () => ({ user, auth, profile, userProfile, logout }),
    [user, auth, profile, userProfile],
  );
};

export default useAuth;
