import { createApi } from '@reduxjs/toolkit/query/react';
import { authBaseQuery } from '../utils/utils';

export const incodeApi = createApi({
  reducerPath: 'incode',
  baseQuery: authBaseQuery({
    baseUrl: process.env.REACT_APP_API_EVENT,
  }),
  endpoints: (build) => ({
    getOnboarding: build.mutation({
      query: () => `/users/incode/onboarding`,
    }),
    getScore: build.mutation({
      query: (body) => ({
        url: `/users/incode/onboarding/score`,
        method: 'POST',
        body,
      }),
    }),
    saveIncodeScore: build.mutation({
      query: (val) => ({
        url: `/users/${val.id}/incode`,
        method: 'POST',
        body: val.bodyScore,
      }),
    }),
  }),
});

export const {
  useGetOnboardingMutation,
  useGetScoreMutation,
  useSaveIncodeScoreMutation
} = incodeApi;
