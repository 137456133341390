import SuperAdminExit from './SearchExit';
import SuperAdminNotice from './SearchNotice';
import useUserClient from '../../../hooks/useUserClient';

const SuperAdminComponent = () => {
  const { client } = useUserClient();
  return (
    client?.id && (
      <>
        <SuperAdminExit />
        <SuperAdminNotice />
      </>
    )
  );
};

export default SuperAdminComponent;
