import { createApi } from '@reduxjs/toolkit/query/react';
import { authBaseQuery } from '../utils/utils';

export const preferencesApi = createApi({
  reducerPath: 'preferencesApi',
  refetchOnMountOrArgChange: true,
  baseQuery: authBaseQuery({
    baseUrl: process.env.REACT_APP_API_EVENT,
  }),
  tagTypes: ['preferences'],
  keepUnusedDataFor: 3600,
  endpoints: (build) => ({
    updateBreakdown: build.mutation({
      query: ({ id, included }) => ({
        url: `events/${id}/fee-breakdown/${included}`,
        method: 'PUT',
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'preferences', id }],
    }),
    updateMaxTicketsPerBooking: build.mutation({
      query: ({ id, tickets }) => ({
        url: `events/${id}/tickets-per-booking/${tickets}`,
        method: 'PUT',
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'preferences', id }],
    }),
  }),
});

export const {
  useUpdateBreakdownMutation,
  useUpdateMaxTicketsPerBookingMutation,
} = preferencesApi;
