import { useNavigate } from 'react-router-dom';
import { Stack, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function CheckinNavbarDocs() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <Stack
      spacing={3}
      sx={{
        px: 5,
        pb: 5,
        mt: 10,
        width: 1,
        textAlign: 'center',
        display: 'block',
      }}
    >
      <Button
        variant="contained"
        onClick={() => navigate('/dashboard/check-in/events-control')}
      >
        {t('checkin.leave')}
      </Button>
    </Stack>
  );
}
