import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

function RecaptchaProvider({ children }) {
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
    >
      {children}
    </GoogleReCaptchaProvider>
  );
}

export default RecaptchaProvider;
