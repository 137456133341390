import React from 'react';
import EmptyStateGlobal from '../../../components/EmptyState';

function NeermeEmptyState() {
  return (
    <EmptyStateGlobal
      title="Preguntas por boleto"
      desc="Crea preguntas personalizadas a cada persona y conoce más a tu audiencia."
      desc2="Tu evento es en la plataforma Neerme, si requieres preguntar información a tus usuarios por favor genera las preguntas por reservación"
      label="Agregar pregunta"
      hiddeButton
    />
  );
}

export default NeermeEmptyState;
