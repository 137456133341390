import PropTypes from 'prop-types';
import { useLocation } from 'react-router';
import { styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, Typography, Link } from '@mui/material';
import {
  useOffSetTop,
  useResponsive,
  cssStyles,
  Logo,
  Iconify,
  IconButtonAnimate,
} from '@boletia/blt-ui';
import { HEADER, NAVBAR } from '@boletia/blt-ui/src/config';
import { UrlIcon } from '@boletia/blt-components/src/Icons';
import AccountPopover from '../../components/AccountPopover';
import useEvent from '../../../hooks/useEvent';
import EventDetailsIcons from '../../../modules/events/components/EventDetailsIcons';
import useAuth from '../../../hooks/useAuth';

// ----------------------------------------------------------------------

const RootStyle = styled(AppBar, {
  shouldForwardProp: (prop) =>
    prop !== 'isCollapse' && prop !== 'isOffset' && prop !== 'verticalLayout',
})(({ isCollapse, isOffset, verticalLayout, theme }) => ({
  ...cssStyles(theme).bgBlur(),
  boxShadow: 'none',
  height: HEADER.MOBILE_HEIGHT,
  zIndex: theme.zIndex.appBar + 1,
  transition: theme.transitions.create(['width', 'height'], {
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up('lg')]: {
    height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH + 1}px)`,
    ...(isCollapse && {
      width: `calc(100% - ${NAVBAR.DASHBOARD_COLLAPSE_WIDTH}px)`,
    }),
    ...(isOffset && {
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
    }),
    ...(verticalLayout && {
      width: '100%',
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
      backgroundColor: theme.palette.background.default,
    }),
  },
}));

// ----------------------------------------------------------------------

DashboardHeader.propTypes = {
  onOpenSidebar: PropTypes.func,
  isCollapse: PropTypes.bool,
  verticalLayout: PropTypes.bool,
};

export default function DashboardHeader({
  onOpenSidebar,
  isCollapse = false,
  verticalLayout = false,
}) {
  const isOffset =
    useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT) && !verticalLayout;

  const isDesktop = useResponsive('up', 'lg');
  const isMobile = useResponsive('down', 'sm');

  const event = useEvent();

  const {
    user: { super_admin: isSuperAdmin },
  } = useAuth();

  const { pathname } = useLocation();
  const showIcons = !isMobile && !pathname.includes('/dashboard');

  return (
    <RootStyle
      isCollapse={isCollapse}
      isOffset={isOffset}
      verticalLayout={verticalLayout}
    >
      <Toolbar
        sx={{
          minHeight: '100% !important',
          px: { lg: 5 },
        }}
      >
        {isDesktop && verticalLayout && <Logo sx={{ mr: 2.5 }} />}
        {!isDesktop && (
          <IconButtonAnimate
            onClick={onOpenSidebar}
            sx={{ mr: 1, color: 'text.primary' }}
          >
            <Iconify icon="eva:menu-2-fill" />
          </IconButtonAnimate>
        )}
        <Typography
          variant="h5"
          translate="no"
          sx={{
            overflow: 'hidden',
            maxHeight: '30px',
            color: 'text.primary',
            textOverflow: 'ellipsis',
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            whiteSpace: 'nowrap',
          }}
          component="div"
        >
          {event?.name}
          {pathname.includes('/dashboard') && isSuperAdmin && (
            <Link
              href={
                window.location.origin.includes('boletia')
                  ? `https://${event.subdomain}.boletia.com/`
                  : `https://${event.subdomain}.plupets.com/`
              }
              target="_BLANK"
              rel="noopener noreferrer"
              sx={{
                fontSize: '24px',
                underline: 'none',
                color: 'primary.main',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <UrlIcon
                sx={{
                  marginRight: '4px',
                  width: '18px',
                  height: '18px',
                }}
              />
            </Link>
          )}
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        <Stack
          direction="row"
          alignItems="center"
          spacing={{ xs: 0.5, sm: 1.5 }}
        >
          {showIcons && <EventDetailsIcons />}
          <AccountPopover />
        </Stack>
      </Toolbar>
    </RootStyle>
  );
}
