import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'ticketTypes',
  initialState: { tickets: [] },
  reducers: {
    setTicketTypes: (state, { payload }) => payload,
  },
});

export const { setTicketTypes } = slice.actions;

export default slice.reducer;

export const selectTicketTypes = (state) => state.ticketTypes;
