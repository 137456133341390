import { createSlice } from '@reduxjs/toolkit';
import { reHydrateStoreEncrypt } from '../utils/local-storage';

const initialState = {
  interviewId: null,
  incodeToken: null,
  showIncodeModal: false,
  eventId: null,
};

const slice = createSlice({
  name: 'incode',
  initialState: reHydrateStoreEncrypt('incode') || initialState,
  reducers: {
    setInterviewIdState: (state, { payload }) => ({
      ...state,
      interviewId: payload,
    }),
    setIncodeTokenState: (state, { payload }) => ({
      ...state,
      incodeToken: payload,
    }),
    setShowIncodeModal: (state, { payload }) => ({
      ...state,
      showIncodeModal: payload,
    }),
    setEventIdState: (state, { payload }) => ({ ...state, eventId: payload }),
  },
});

export const { setInterviewIdState } = slice.actions;
export const { setIncodeTokenState } = slice.actions;
export const { setShowIncodeModal } = slice.actions;
export const { setEventIdState } = slice.actions;

export default slice.reducer;

export const selectInterviewId = (state) => state.incode.interviewId;
export const selectShowIncodeModal = (state) => state.incode.showIncodeModal;
export const selectIncodeToken = (state) => state.incode.incodeToken;
export const selectEventId = (state) => state.incode.eventId;
