import { Alert, AlertTitle, Collapse, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';

function AccountAlert({ open }) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '12px',
        transition: '0.2s',
        marginBottom: '36px',
      }}
    >
      <Collapse in={open}>
        <Alert severity="warning">
          <AlertTitle>¿Necesitas cambios?</AlertTitle>
          <Typography variant="caption">
            En caso de requerir cambios fiscales/financieros favor de escribir
            al email{' '}
            <Typography
              component="span"
              variant="caption"
              sx={{ fontWeight: 700 }}
            >
              {' '}
              contratos@boletia.com{' '}
            </Typography>
          </Typography>
        </Alert>
      </Collapse>
    </Box>
  );
}

export default AccountAlert;
