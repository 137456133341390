import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const googleMapsApi = createApi({
  reducerPath: 'google',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_GOOGLE_MAPS,
  }),
  tagTypes: ['Google'],
  endpoints: (build) => ({
    getGeoCode: build.mutation({
      query: (address) => ({
        url: `/geocode/json?address=${encodeURIComponent(address)}&key=${
          process.env.REACT_APP_GOOGLE_API_KEY
        }`,
        method: 'GET',
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'Google', id })),
              { type: 'Google', id: 'LIST' },
            ]
          : [{ type: 'Google', id: 'LIST' }],
    }),
  }),
});

export const { useGetGeoCodeMutation } = googleMapsApi;
