import { createApi } from '@reduxjs/toolkit/query/react';
import { authBaseQuery } from '../utils/utils';

export const insidersApi = createApi({
  reducerPath: 'insidersApi',
  baseQuery: authBaseQuery({
    baseUrl: process.env.REACT_APP_API_EVENT,
  }),
  tagTypes: ['insiders'],
  endpoints: (build) => ({
    getInsiderRol: build.query({
      query: (userId) => ({
        url: '/users/check-insider',
        method: 'GET',
        headers: {
          user_id: userId,
        },
      }),
    }),
    getinsidersStatus: build.query({
      query: (eventId) =>
        `/forms/events/${eventId}/preferences/questions-in-insiders`,
      providesTags: ['insiders'],
    }),
    getMaxPurchaseTime: build.query({
      query: (eventId) => `/forms/events/${eventId}/max-purchase-time`,
      providesTags: ['insiders'],
    }),
    updateInsidersStatus: build.mutation({
      query(data) {
        const { eventId, show } = data;
        return {
          url: `/forms/events/${eventId}/preferences/questions-in-insiders`,
          method: 'PATCH',
          body: { show },
        };
      },
      invalidatesTags: ['insiders'],
    }),
    updateMaxPurchaseTime: build.mutation({
      query(data) {
        const { eventId, minutes } = data;
        return {
          url: `/forms/events/${eventId}/max-purchase-time`,
          method: 'PATCH',
          body: minutes,
        };
      },
      invalidatesTags: ['insiders'],
    }),
  }),
});

export const {
  useGetInsiderRolQuery,
  useGetinsidersStatusQuery,
  useGetMaxPurchaseTimeQuery,
  useUpdateInsidersStatusMutation,
  useUpdateMaxPurchaseTimeMutation,
} = insidersApi;
