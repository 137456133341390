import { createApi } from '@reduxjs/toolkit/query/react';
import { authBaseQuery } from '../utils/utils';

export const billboardApi = createApi({
  reducerPath: 'billboardApi',
  refetchOnMountOrArgChange: true,
  baseQuery: authBaseQuery({
    baseUrl: process.env.REACT_APP_API_BILLBOARDS,
  }),
  tagTypes: ['Billboards'],
  keepUnusedDataFor: 3600,
  endpoints: (build) => ({
    getBillboards: build.query({
      query: () => 'billboards',
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'Billboards', id })),
              { type: 'Billboards', id: 'LIST' },
            ]
          : [{ type: 'Billboards', id: 'LIST' }],
    }),
    addBillboard: build.mutation({
      query: (body) => ({
        url: `billboards`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'Billboards', id: 'LIST' }],
    }),
    getBillboard: build.query({
      query: (id) => `billboards/${id}`,
      providesTags: (result, error, id) => [{ type: 'Billboards', id }],
    }),
    updateBillboard: build.mutation({
      query: ({ id, ...patch }) => ({
        url: `billboards/${id}`,
        method: 'PATCH',
        body: patch,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'Billboards', id }],
    }),
    updateBillboardStatus: build.mutation({
      query: ({ id, status }) => ({
        url: `billboards/${id}/status/${status}`,
        method: 'GET',
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'Billboards', id }],
    }),
    deleteBillboard: build.mutation({
      query(id) {
        return {
          url: `billboards/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: (result, error, id) => [{ type: 'Billboards', id }],
    }),
    validateSubdomain: build.mutation({
      query({ id, subdomain }) {
        return {
          url: `billboards/${id}/verify/${subdomain}`,
          method: 'GET',
        };
      },
    }),
    highlightEvent: build.mutation({
      query({ id, eventId }) {
        return {
          url: `billboards/${id}/highlighted-event`,
          method: 'POST',
          body: { event_id: eventId },
        };
      },
      invalidatesTags: (result, error, { id }) => [{ type: 'Billboards', id }],
    }),
  }),
});

export const {
  useGetBillboardQuery,
  useGetBillboardsQuery,
  useAddBillboardMutation,
  useUpdateBillboardMutation,
  useUpdateBillboardStatusMutation,
  useDeleteBillboardMutation,
  useValidateSubdomainMutation,
  useHighlightEventMutation,
} = billboardApi;
