import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  Alert,
  Box,
  Button,
  Card,
  Collapse,
  FormControlLabel,
  Grid,
  InputAdornment,
  Switch,
  Typography,
} from '@mui/material';
import { InfoIcon, PlusIcon } from '@boletia/blt-components/src/Icons';
import { Modal } from '@boletia/blt-components/src/Modal';
import { Message } from '@boletia/blt-components/src/Message';
import { FormSelect, FormsWrapper } from '@boletia/blt-components/src/Forms';
import { useCreateTicketQuestionsMutation } from '../../../store/api/ticket-surveys-api';
import {
  useGetinsidersStatusQuery,
  useGetMaxPurchaseTimeQuery,
  useUpdateInsidersStatusMutation,
  useUpdateMaxPurchaseTimeMutation,
} from '../../../store/api/insiders.api';
import { setNotification } from '../../../store/slices/notifications.slice';
import TicketForm from './Modals/TicketForm';
import DynamicForm from './DynamicForm';

const min = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
  23, 24, 25, 26, 27, 28, 29, 30,
];

const MessageModal = ({
  open,
  handleAccpet,
  handleCancel,
  title,
  subtitle,
}) => (
  <Modal
    color="primary"
    open={open}
    confirmation="Aceptar"
    handleAccept={() => handleAccpet()}
    confirmationCancel="Cancelar"
    handleCancel={() => handleCancel()}
  >
    <Message title={title} subtitle={subtitle} severity="success" filled />
  </Modal>
);

MessageModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleAccpet: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
};

function TicketFormTemplate({
  questionList,
  setQuestionList,
  ticketTypes,
  handleModal,
  cancelTicketForm,
  canEdit,
}) {
  const [active, setActive] = useState(false);
  const [open, setOpen] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [editData, setEditData] = useState(null);
  const [changeData, setChangeData] = useState(false);
  const [startEdit, setStartEdit] = useState(false);

  const params = useParams();

  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();

  const {
    data: insidersData,
    isLoading: isLoadingInsiders,
    isFetching: isFetchingInsiders,
  } = useGetinsidersStatusQuery(params.event);

  const {
    data: maxTimeData,
    isLoading: isLoadingGetMaxTimeData,
    isFetching: isFetchingMaxTimeData,
  } = useGetMaxPurchaseTimeQuery(params.event);

  const handleCreatedNotification = (message) => {
    const notificationOptions = {
      message,
      severity: 'success',
      verticalPos: 'top',
      horizontalPos: 'right',
      showNotification: true,
    };
    dispatch(setNotification(notificationOptions));
  };

  const [addQuestions, { isLoading: isLoadingAddQuestions }] =
    useCreateTicketQuestionsMutation();

  const [updateInsiders] = useUpdateInsidersStatusMutation();

  const [updateMaxTime] = useUpdateMaxPurchaseTimeMutation();

  const handleUpdateInsiders = () => {
    updateInsiders({ eventId: params.event, show: active }).then(
      () => setOpenModal(false),
      enqueueSnackbar(
        active
          ? 'Se han activado las preguntas en la app Insiders'
          : 'Se han desactivado las preguntas en la app Insiders',
      ),
    );
  };

  const handleCancel = () => {
    setActive(!active);
    setOpenModal(false);
  };

  const handleSetEditData = (d, index) => {
    const copyQuestions = [...questionList];
    copyQuestions[index] = { ...d };
    setQuestionList([...copyQuestions]);
  };

  const handleSubmit = (d) => {
    setQuestionList([...questionList, d]);
    setStartEdit(true);
  };

  const submitQuestions = (questions, text) => {
    const data = { eventId: params.event, questions };
    addQuestions(data)
      .catch((error) => console.error('Error:', error))
      .then((response) => {
        if (response.error) {
          handleModal(true, response.error.data.message, true);
        } else {
          handleModal(true, text);
        }
      });
  };

  const handleSubmitMaxMinutes = (minutes) => {
    minutes.minutes = parseInt(minutes.minutes, 10);
    updateMaxTime({ eventId: params.event, minutes }).then(() => {
      enqueueSnackbar('Se ha actualizado el tiempo máximo de compra');
    });
  };

  useEffect(() => {
    if (insidersData !== undefined) setActive(insidersData.show);
  }, insidersData);

  useEffect(() => {
    setChangeData(false);
  }, [editData, questionList]);

  return (
    <>
      <MessageModal
        open={openModal}
        handleAccpet={handleUpdateInsiders}
        handleCancel={handleCancel}
        title={
          active
            ? '¿Estás seguro que deseas activar las preguntas en el app de insider?'
            : '¿Estás seguro que deseas desactivar las preguntas en el app de insider?'
        }
        subtitle={
          active
            ? 'Al aceptar, las preguntas aparecerán en la app de insiders.'
            : 'Al aceptar, las preguntas ya no aparecerán en la app de insiders.'
        }
      />
      <Box sx={{ width: '100%' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h6" sx={{ marginBottom: '16px' }}>
            Pregunta por boleto &nbsp;
            <InfoIcon
              fontSize="small"
              htmlColor="#757575"
              sx={{
                '&:hover': {
                  cursor: 'pointer',
                },
              }}
              onClick={() => {
                setOpen(!open);
              }}
            />
          </Typography>

          {canEdit && (
            <Button
              variant="contained"
              color="info"
              disabled={questionList.length > 16}
              onClick={() => setOpenForm(true)}
            >
              <PlusIcon sx={{ width: 13, height: 13 }} /> &nbsp; &nbsp;Agregar
              pregunta
            </Button>
          )}
        </Box>
        <Collapse in={open} sx={{ margin: '20px 0 24px 0' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
            <Alert severity="info">
              <Typography variant="label3">
                <Typography component="span" sx={{ fontWeight: '600' }}>
                  Nombre, Apellido y Email &nbsp;
                </Typography>
                son campos por defecto, no es necesario que los agregues.
              </Typography>
            </Alert>
            <Alert severity="info">
              Las preguntas por boleto que configures aparecerán de manera
              automática al asistente en el proceso de compra.
            </Alert>
          </Box>
        </Collapse>
        <TicketForm
          open={openForm}
          handleModal={setOpenForm}
          value={editData}
          onSubmit={(d) => handleSubmit(d)}
          ticketTypeList={ticketTypes}
        />
        <DynamicForm
          questionList={questionList}
          setQuestionList={setQuestionList}
          submitQuestions={submitQuestions}
          onCancel={cancelTicketForm}
          editData={handleSetEditData}
          startEdit={startEdit}
          setStartEdit={setStartEdit}
          ticketTypesList={ticketTypes}
          canEdit={canEdit}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            gap: '15px',
            flexGrow: 1,
            marginTop: '24px',
          }}
        >
          <Card sx={{ width: { xs: '100%', md: '50%' } }}>
            <Box
              sx={{
                padding: { xs: '15px 10px', md: '16px 20px' },
                boxSizing: 'border-box',
              }}
            >
              <Typography
                component="p"
                variant="label3"
                color="#757575"
                sx={{ fontWeight: '600' }}
              >
                Opciones
              </Typography>
              {!isLoadingGetMaxTimeData &&
                !isFetchingMaxTimeData !== undefined && (
                  <FormsWrapper
                    onSubmit={handleSubmitMaxMinutes}
                    value={maxTimeData}
                  >
                    <Grid
                      container
                      sx={{
                        alignItems: 'center',
                        display: 'flex',
                        gap: '15px',
                        marginTop: '20px',
                      }}
                    >
                      <Grid item xs={8}>
                        <FormSelect
                          disabled={!canEdit}
                          name="minutes"
                          label="Seleccione el tiempo máximo de compra"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Box
                                  sx={{
                                    padding: '16px 30px 16px 30px',
                                    marginRight: '-14px',
                                    backgroundColor: '#f4f6f8',
                                    borderRadius: `0 10px 10px 0`,
                                    fontSize: '14px',
                                    fontWeight: 400,
                                    color: (theme) => theme.palette.grey[800],
                                  }}
                                >
                                  minutos
                                </Box>
                              </InputAdornment>
                            ),
                          }}
                        >
                          <option hidden selected>
                            Selecciona una opción
                          </option>
                          {min.map((minute) => (
                            <option value={minute} key={minute}>
                              {minute}
                            </option>
                          ))}
                        </FormSelect>
                      </Grid>
                      <Grid item xs={3}>
                        <Button
                          variant="contained"
                          type="submit"
                          disabled={!canEdit}
                        >
                          Actualizar
                        </Button>
                      </Grid>
                    </Grid>
                  </FormsWrapper>
                )}
            </Box>
          </Card>
          <Card sx={{ width: { md: '50%', sx: '100%' } }}>
            <Box
              sx={{
                padding: { xs: '15px 10px ', md: '16px 20px' },
                boxSizing: 'border-box',
                background: '#fff',
              }}
            >
              <Typography
                component="p"
                variant="label3"
                color="#757575"
                sx={{ fontWeight: '600' }}
              >
                Insiders
              </Typography>
              <Typography
                component="p"
                variant="label3"
                sx={{ marginTop: '12px' }}
              >
                Las preguntas de esta forma aparecerán en la app de insiders:
              </Typography>
              <Box
                sx={{
                  marginTop: '8px',
                  display: 'flex',
                  gap: '15px',
                }}
              >
                <FormControlLabel
                  control={
                    <Switch
                      disabled={!canEdit}
                      color="primary"
                      checked={active}
                      onChange={(event) => {
                        setOpenModal(true);
                        setActive(event.target.checked);
                      }}
                    />
                  }
                  label={active ? 'Activado' : 'Desactivado'}
                />
              </Box>
            </Box>
          </Card>
        </Box>
      </Box>
    </>
  );
}

TicketFormTemplate.propTypes = {
  questionList: PropTypes.array,
  setQuestionList: PropTypes.func,
  ticketTypes: PropTypes.array,
  handleModal: PropTypes.func,
  cancelTicketForm: PropTypes.func,
};

TicketFormTemplate.defaultProps = {
  questionList: [],
  setQuestionList: () => {},
  ticketTypes: [],
  handleModal: () => {},
  cancelTicketForm: () => {},
};

export default TicketFormTemplate;
