import { Modal } from '@boletia/blt-components';
import { Box } from '@mui/material';
import React from 'react';

function FormModal({ title, children, open, handleModal }) {
  return (
    <Modal
      header={title}
      widthDefault="50%"
      open={open}
      handleClose={() => {
        handleModal(false);
      }}
    >
      <Box
        sx={{
          borderTop: '1px solid #EDEFF2',
        }}
      >
        {children}
      </Box>
    </Modal>
  );
}

export default FormModal;
